<div style="position: relative;
    height: 100%;" [class.loading-content]="isPageLoading" *transloco="let t">
  <ngx-spinner [fullScreen]="false" type="ball-spin" color="var(--spark-primary)"> </ngx-spinner>
  <div class="header">
    <div class="name-box">
      <h3>{{user?.full_name}}
      </h3>
      <p class="secondary-text">{{user?.user_login?.user_role_name?.user_role_name}}</p>
    </div>
  </div>
  <div fxLayout="column" class="profile-content">
    <div class="profile-card">

      <div class="profile-image">
        <img src="{{ user.profile_photo_path ? user.profile_photo_path : 'assets/placeholder/default.jpg'}}">

        <div *ngIf="!user.profile_photo_path" matRipple class="profile-edit">
          <label for="file">

            <mat-icon>local_see</mat-icon>
          </label><input type="file" name="file" class="hidden-file-input" (change)="fileChangeEvent($event)">

        </div>

        <ng-container *ngIf="user.profile_photo_path">
          <div matRipple class="profile-edit">
            <mat-icon class="icon-size-5 text-white cursor-pointer" [matMenuTriggerFor]="menu">edit
            </mat-icon>
          </div>
          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <label for="file">
                {{t('change')}}
              </label>
              <input type="file" name="file" class="hidden-file-input" (change)="fileChangeEvent($event)">

            </button>
            <button mat-menu-item (click)="removeProfileImage()">{{t('remove')}}</button>
          </mat-menu>
        </ng-container>

      </div>
      <div class="profile-info">
        <div class="profile-single-info">
          <mat-icon class="text-accent">account_circle</mat-icon> <span class="secondary-text">
            {{ user.user_login?.username}}</span>
        </div>
        <div class="profile-single-info" *ngIf="user?.dob">
          <mat-icon class="text-accent">cake</mat-icon> <span class="secondary-text">{{user?.dob | date:'dd
            MMM yyyy'}}</span>
        </div>
        <div class="profile-single-info">
          <mat-icon class="text-accent">call</mat-icon> <span class="secondary-text">+{{user.country?.phonecode}}
            {{user.mobile_no}}</span>
        </div>

      </div>

    </div>
    <div class="details-box mb-5">

      <mat-card>
        <mat-card-title>
          {{t('personal_details.title.personal_details')}}
        </mat-card-title>

        <div class="personal-details">
          <form [formGroup]="userForm">
            <div class="flex" fxLayout="row" fxLayoutGap="1%" fxLayoutGap.lt-sm="0px" fxLayout.lt-sm="column">
              <mat-form-field fxFlex="0 1 14%" class="" appearance="outline" floatLabel="never">
                <mat-select formControlName="prefix_drc_key">
                  <mat-option *ngFor="let prefix of userPrefixes" [value]="prefix.prefix_drc_key">
                    {{prefix.name_prefix}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" fxFlex="1 0 0">
                <mat-label>{{t('personal_details.title.first_name')}}</mat-label>
                <input matInput formControlName="first_name" type="text">
              </mat-form-field>
              <mat-form-field appearance="outline" fxFlex="1 0 0">
                <mat-label>{{t('personal_details.title.last_name')}}</mat-label>
                <input matInput formControlName="last_name" type="text">
              </mat-form-field>
            </div>
            <mat-form-field appearance="outline">
              <mat-label>{{t('personal_details.title.email')}}</mat-label>
              <input matInput formControlName="email_id" type="text">
            </mat-form-field>
            <div fxLayout="row" fxLayoutGap="8px" fxLayoutGap.lt-sm="0px" fxLayout.lt-sm="column">
              <mat-form-field appearance="outline" fxFlex="0 1 120px" fxLayoutGap="8px" fxFlex.lt-sm="1 0 0"
                class="country-select" floatLabel="never">
                <mat-select [(ngModel)]="selectedCountryCode" formControlName="country_drc_key">
                  <mat-option *ngFor="let code of countryCodes" [value]="code.country_drc_key"
                    (change)="changeCountry(code)">+
                    {{code.phonecode}} {{code.iso3}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field fxFlex="1 0 0" appearance="outline" class="mobile-box">
                <mat-label>{{t('personal_details.title.mobile')}}</mat-label>
                <input appOnlyNumbers formControlName="mobile_no" matInput type="text" maxlength="10">
                <mat-error *ngIf="userForm.get('mobile_no').errors?.required">
                  {{t('personal_details.validation.please_enter_your_mobile')}}
                </mat-error>
                <mat-error *ngIf="userForm.get('mobile_no').errors?.serverType">
                  {{userForm.get('mobile_no').errors?.serverType.message}}
                </mat-error>
                <mat-error *ngIf="userForm.get('mobile_no').errors?.minlength">
                  {{t('personal_details.validation.please_enter_valid_mobile')}}
                </mat-error>
              </mat-form-field>
            </div>
            <mat-radio-group formControlName="gender">
              <mat-radio-button [value]="1">{{t('personal_details.title.male')}}</mat-radio-button>
              <mat-radio-button [value]="0">{{t('personal_details.title.female')}}</mat-radio-button>
            </mat-radio-group>
            <mat-form-field appearance="outline" class="mt-6">
              <mat-label>{{t('personal_details.title.date_of_birth')}}</mat-label>
              <input matInput formControlName="dob" [matDatepicker]="picker">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error
                *ngIf="userForm.get('dob').errors?.required">{{t('personal_details.validation.please_enter_dob')}}
              </mat-error>
            </mat-form-field>
            <div class="flex flex-col md:gap-4 md:flex-row">
              <mat-form-field appearance="outline" class="country-select" floatLabel="never">
                <mat-select formControlName="state_drc_key">
                  <mat-option [value]="state.state_drc_key" *ngFor="let state of states">
                    {{state.state_name}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="country-select" floatLabel="never">
                <mat-select formControlName="city_drc_key">
                  <mat-option [value]="city.city_drc_key" *ngFor="let city of cities">
                    {{city.city_name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="right-align-controls">
              <button mat-button class="success-button add-round-button" (click)="editPersonalDetails()"
                [disabled]="userForm.invalid || isLoading">
                <span *ngIf="!isLoading">{{t('save')}}</span>
                <div *ngIf="isLoading" style="padding: 8px;">
                  <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
                </div>
              </button>
            </div>
          </form>
        </div>
      </mat-card>
      <br>
      <mat-card>
        <mat-card-title>{{t('auth.title.security')}}</mat-card-title>
        <div class="card-details">
          <table>
            <tr>
              <td class="title">{{t('auth.title.password')}}</td>
              <td><a style="cursor: pointer;" class="link"
                  (click)="openChangePasswordDialog()">{{t('auth.title.change_password')}}</a></td>
            </tr>
            <tr>
              <td class="title">{{t('auth.title.last_password_change_date')}}</td>
              <td class="title">{{user.user_login?.last_password_changed_on | date:'dd MMM yyyy h:mm a'}}
              </td>
            </tr>

          </table>
        </div>
      </mat-card>
      <br>
      <mat-card>
        <mat-card-title>{{t('auth.title.last_login_details')}}</mat-card-title>

        <div class="card-details">
          <table>
            <tr>
              <td class="title">{{t('auth.title.last_login')}}</td>
              <td>{{user?.user_login_activity?(user?.user_login_activity?.created_at | date:'yyyy-MM-dd
                h:mm a' ):'Now'}}
              </td>
            </tr>
            <tr>
              <td class="title">{{t('auth.title.os')}}</td>
              <td>{{user.user_login_activity?.os_version?(user.user_login_activity?.os_version):'NA'}}
                {{user.user_login_activity?.os?((user.user_login_activity.os)):''}}
              </td>
            </tr>
            <tr>
              <td class="title">{{t('auth.title.device')}}</td>
              <td class="title">
                {{user.user_login_activity?.device_brand?user.user_login_activity?.device_brand:'NA'}}
                {{user.user_login_activity?.device_model?user.user_login_activity?.device_model:''}}
              </td>
            </tr>
            <tr>
              <td class="title">{{t('auth.title.browser')}}</td>
              <td class="title">
                {{user.user_login_activity?.browser_name?user.user_login_activity?.browser_name:'NA'}}
                {{user.user_login_activity?.browser_version?user.user_login_activity.browser_version:''}}
              </td>
            </tr>
            <tr>
              <td class="title">{{t('auth.title.location')}}</td>
              <td class="title">
                {{user.user_login_activity?.city_name?user.user_login_activity?.city_name:'NA'}}
                {{user.user_login_activity?.city_name?user.user_login_activity?.zip_code:''}}
              </td>
            </tr>
            <tr>
              <td class="title">{{t('auth.title.ip_address')}}</td>
              <td class="title">
                {{user.user_login_activity?.ip_address?user.user_login_activity?.ip_address:'NA'}}
              </td>
            </tr>

          </table>
        </div>
      </mat-card>

    </div>

  </div>



</div>