import { Route } from '@angular/router';
import { InitialDataResolver } from './app.resolvers';
import { VerifyUserComponent } from './auth-pages/login/verify-user/verify-user.component';
import { MyAccountComponent } from './auth-pages/my-account/my-account.component';
import { MyAccountService } from './auth-pages/my-account/my-account.service';
import { BugsReportsComponent } from './components/bugs-reports/bugs-reports.component';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { QuoteService } from './components/quote/quote.service';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { ErrorPageComponent } from './core/pages/error-page/error-page.component';
// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: '',
    loadChildren: () => import('./auth-pages/auth-pages.module').then(m => m.AuthPagesModule),
  },
  {
    path: '',
    resolve: {
      initialData: InitialDataResolver
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'profile',
        component: MyAccountComponent,
        resolve: {
          data: MyAccountService
        },
        data: {
          requireLogin: true
        }
      },
      {
        path: 'quotes',
        resolve: {
          data: QuoteService
        },
        loadChildren: () => import('./components/quote/quote.module').then(m => m.QuoteModule),
      },
      {
        path: 'clients', data: {
          requireLogin: true
        },
        loadChildren: () => import('./components/client/client.module').then(m => m.ClientModule),
      },
      {
        path: 'users/internal', data: {
          requireLogin: true,
          breadcrumb: 'Users',
        },
        loadChildren: () => import('./components/user/user.module').then(m => m.UserModule),
      },
      {
        path: 'users/external', data: {
          requireLogin: true,
          breadcrumb: 'Users',
        },
        loadChildren: () => import('./components/external-users/external-users.module').then(m => m.ExternalUsersModule),
      },
      {
        path: 'feedbacks',
        loadChildren: () => import('./components/feedback/feedback.module').then(m => m.FeedbackModule),
        data: {
          requireLogin: true
        }
      },
      {
        path: 'subscriptions', data: {
          requireLogin: true,
        },
        loadChildren: () => import('./components/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
      },
      {
        path: 'projects',
        data: {
          requireLogin: true,
          breadcrumb: 'Projects',
        },
        loadChildren: () => import('./components/project/project.module').then(m => m.ProjectModule),

      },
      {
        path: 'product/partners',
        data: {
          requireLogin: true,
          breadcrumb: 'Partners',
        },
        loadChildren: () => import('./components/partner/partner.module').then(m => m.PartnerModule),
      },
      {
        path: 'product/list',
        data: {
          requireLogin: true,
          breadcrumb: 'Products',
        },
        loadChildren: () => import('./components/product/product.module').then(m => m.ProductModule),
      },
      {
        path: 'product/problem-report',
        data: {
          requireLogin: true,
          breadcrumb: 'Problem Reports',
        },
        loadChildren: () => import('./components/problem-report/problem-report.module').then(m => m.ProblemReportModule),
      },
      {
        path: 'product/payouts',
        data: {
          requireLogin: true,
          breadcrumb: 'Payouts',
        },
        loadChildren: () => import('./components/payouts/payouts.module').then(m => m.PayoutsModule),
      },
      {
        path: 'product/enquiry',
        data: {
          requireLogin: true,
          breadcrumb: 'Enquiry',
        },
        loadChildren: () => import('./components/product-enquiry/product-enquiry.module').then(m => m.ProductEnquiryModule),
      },
      {
        path: 'textlocal',
        data: {
          requireLogin: true,
          breadcrumb: 'Textlocal',
        },
        loadChildren: () => import('./components/txtlocal/txtlocal.module').then(m => m.TxtlocalModule),
      },
      {
        path: 'verifyClient',
        data: {
          requireLogin: false
        },
        children: [
          {
            path: '',
            component: VerifyUserComponent,
          },
          {
            path: ':id',
            component: VerifyUserComponent,
          }
        ]
      },
      {
        path: 'attributes',
        data: {
          requireLogin: true
        },
        loadChildren: () => import('./components/attribute/attribute.module').then(m => m.AttributeModule),
      },
      {
        path: 'accessibility',
        data: {
          requireLogin: true
        },
        loadChildren: () => import('./components/accessibility/accessibility.module').then(m => m.AccessibilityModule),
      },
      {
        path: 'cpanel',
        data: {
          requireLogin: true
        },
        loadChildren: () => import('./components/cpanel/cpanel.module').then(m => m.CpanelModule),
      },
      {
        path: 'pages',
        data: {
          requireLogin: true
        },
        loadChildren: () => import('./components/pages/pages.module').then(m => m.PagesModule),
      },
      {
        path: 'bug-reports',
        loadChildren: () => import('./components/bugs-reports/bugs-reports.module').then(m => m.BugsReportsModule),
      },
      {
        path: 'suggestions',
        loadChildren: () => import('./components/suggestions/suggestions.module').then(m => m.SuggestionsModule),
      },
      {
        path: 'scheduled-jobs',
        data: {
          requireLogin: true
        },
        loadChildren: () => import('./components/scheduled-jobs/scheduled-jobs.module').then(m => m.ScheduledJobsModule),
      },
      {
        path: 'organization-info',
        data: {
          requireLogin: true
        },
        loadChildren: () => import('./components/company-info/company-info.module').then(m => m.CompanyInfoModule),
      },
      {
        path: 'career',
        data: {
          requireLogin: true
        },
        loadChildren: () => import('./components/career/career.module').then(m => m.CareerModule),
      },

    ]
  },
  {
    path: '',
    data: {
      layout: 'empty'
    },
    children: [
      {
        path: 'error',
        component: ErrorPageComponent,
      },
      {
        path: 'error/:type',
        component: ErrorPageComponent,
      },
    ]
  },
  {
    path: '',
    data: {
      layout: 'empty'
    },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ]
  }
];
