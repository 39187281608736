import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MyAccountService } from 'src/app/auth-pages/my-account/my-account.service';
import { UtilityService } from 'src/app/core/common-service/utility.service';

@Component({
  selector: 'app-password-confirm-modal',
  templateUrl: './password-confirm-modal.component.html',
  styleUrls: ['./password-confirm-modal.component.scss']
})
export class PasswordConfirmModalComponent implements OnInit {
  checkExistingPasswordFormGroup: FormGroup;
  isLoading = false;
  constructor(
    public dialogRef: MatDialogRef<PasswordConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _myAccountService: MyAccountService,
    public _utility: UtilityService) { }

  ngOnInit(): void {
    this.checkExistingPasswordFormGroup = this._formBuilder.group({
      password: ['', Validators.required]
    });
  }

  checkPassword() {
    this.isLoading = true;
    this._myAccountService.checkPassword(this.checkExistingPasswordFormGroup.get('password').value).then(
      (data: any) => {
        if (data.success == 1) {
          this.checkExistingPasswordFormGroup.get('password').setErrors({ 'incorrect': false })
          this.checkExistingPasswordFormGroup.reset()
          this.dialogRef.close('success')
        }
        else {
          this.checkExistingPasswordFormGroup.get('password').setErrors({ 'incorrect': true })
        }

      }).catch(error => {
        this.checkExistingPasswordFormGroup.get('password').setErrors({ 'checkError': true })
      }).finally(() => {
        this.isLoading = false;
      })
  }

}
