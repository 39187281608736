import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthModule } from './auth/auth.module';
import { TranslocoCoreModule } from './transloco/transloco.module';
import { DateCountdownPipe } from './pipes/date-countdown.pipe';
import { CommonComponentModule } from '../components/common/common-component.module';
import { CommonPagesModule } from './pages/common-pages.module';

@NgModule({
  imports: [
    AuthModule,
    FormsModule,
    ReactiveFormsModule,
    CommonComponentModule,
    CommonPagesModule,
    //AngularFireModule.initializeApp(environment.firebaseConfig),
    //TranslocoCoreModule,
    HttpClientModule
  ],
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(
    @Optional() @SkipSelf() parentModule?: CoreModule
  ) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }
  }
}
