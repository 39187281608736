<div class="middle-box mat-app-background" *transloco="let t">
  <img class="logo" [src]="(_configService.config$ | async).logo1" alt="">
  <div class="form-group">
    <form [formGroup]="unlockForm" (submit)="unlock()">
      <div class="control-box">
        <mat-form-field appearance="outline">
          <mat-label>{{t('auth.title.username')}}</mat-label>
          <input formControlName="username" #emailId matInput type="text" appLowercase>
          <mat-error *ngIf="unlockForm.get('username').errors?.required">
            {{t('auth.validation.please_enter_your_username')}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="control-box">
        <mat-form-field appearance="outline">
          <mat-label>{{t('auth.title.password')}}</mat-label>
          <input formControlName="password" matInput type="password">
          <mat-error *ngIf="unlockForm.get('password').errors?.required">
            {{t('auth.validation.please_enter_your_password')}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="control-box">
        <button mat-button class="w-full bg-green-700 text-white add-round-button"
          [disabled]="unlockForm.invalid || isLoading">
          <span *ngIf="!isLoading">
            <mat-icon class="mr-2 icon-size-5">lock_open</mat-icon>{{t('auth.title.unlock_session')}}
          </span>
          <div *ngIf="isLoading" style="padding: 8px;">
            <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
          </div>
        </button>
      </div>
      <p class="secondary-text text-sm mt-4 float-right">
        <a href="/login">{{t('auth.title.back_to_login')}}</a>
      </p>
    </form>
  </div>



</div>