import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss']
})
export class ImageCropperModelComponent implements OnInit {

  isLoading = true;
  isUploading = false;
  aspectRatio = 1 / 1;
  isRound = false
  constructor(
    public dialogRef: MatDialogRef<ImageCropperModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.imageChangedEvent = this.data.image;
    if (this.data?.aspectRatio) this.aspectRatio = this.data?.aspectRatio
    if (this.data?.isRound) this.isRound = this.data?.isRound
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.isLoading = false;
    // show cropper
  }
  cropperReady() {
    // cropper ready

  }
  loadImageFailed() {
    this.isLoading = false;
    // show message
  }

  setImage() {
    this.dialogRef.close(this.croppedImage)
  }

}
