import { Component, OnInit } from '@angular/core';
import { MyAccountService } from './my-account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/app.service';
import { MatDialog } from '@angular/material/dialog';
import { EditPersonalModelComponent } from './edit-personal-model/edit-personal-model.component';
import { ProfileImageModelComponent } from './profile-image-model/profile-image-model.component';
import { ChangePasswordModelComponent } from './change-password-model/change-password-model.component';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/user/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { takeUntil } from 'rxjs/operators';
import { CommonApiService } from 'src/app/core/common-service/common-api.service';
import { TranslocoService } from '@ngneat/transloco';
import { formatDate } from '@angular/common';
import { CommonUserService } from 'src/app/components/common/common-user.service';
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  user: any = {};
  userForm: FormGroup;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  countryCodes: { country_drc_key: string; iso3: string; phonecode: number; selected: number; }[];
  userPrefixes: any;
  countries: any;
  isPageLoading: boolean = true;
  isPageError: boolean;
  selectedCountryCode: any;
  states: any;
  cities: any;
  isLoading = false;
  constructor(
    private _myAccountService: MyAccountService,
    private _userService: UserService,
    private spinner: NgxSpinnerService,
    private _router: Router,
    private _utility: UtilityService,
    private _fb: FormBuilder,
    private _commonService: CommonApiService,
    private _commonUserService: CommonUserService,
    private _translocoService: TranslocoService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.userForm = this._fb.group({
      'user_details_key': [''],
      'first_name': ['', Validators.required],
      'last_name': ['', Validators.required],
      'mobile_no': ['', [Validators.required, Validators.minLength(10)]],
      'email_id': ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]],
      'country_drc_key': ['99', Validators.required],
      'state_drc_key': ['', Validators.required],
      'city_drc_key': ['', Validators.required],
      'prefix_drc_key': [1, Validators.required],
      'gender': [''],
      'dob': [''],
    });
    this.countryCodes = [
      { 'country_drc_key': '99', 'iso3': "IND", 'phonecode': 91, 'selected': 1 }
    ]
    this.spinner.show()
    Promise.all([
      this._myAccountService.getUserProfile().then((data) => {
        if (data != null) {
          this.user = data
          this.userForm.patchValue({
            'user_details_key': this.user.user_details_key,
            'first_name': this.user.first_name,
            'last_name': this.user.last_name,
            'mobile_no': this.user.mobile_no,
            'email_id': this.user.email_id,
            'country_drc_key': this.user.country.country_drc_key,
            'state_drc_key': this.user.state.state_drc_key,
            'city_drc_key': this.user.city.city_drc_key,
            'prefix_drc_key': this.user.user_name_prefix.prefix_drc_key,
            'gender': this.user.gender,
            'dob': formatDate(this.user.dob, 'yyyy-MM-dd', 'en-us'),
          })
          this._commonService.getStatesByCountry(this.user.country.country_drc_key).then((data: any) => {
            this.states = data;
          })
          this._commonService.getCitiesByState(this.user.state.state_drc_key).then((data: any) => {
            this.cities = data;
          })
        }
      }),
      this._commonService.getPrefixes().then((data: any) => {
        this.userPrefixes = data;
      }),
      this._commonService.getCountryCode().then((data: any) => {
        this.countryCodes = data;
        this.selectedCountryCode = data.find(c => c.selected).country_drc_key;
      }),
      this._commonService.getCountries().then((data: any) => {
        this.countries = data;
      }),

    ]).finally(() => {
      this.spinner.hide();
      this.isPageLoading = false;
    }).catch(() => {
      this.isPageError = true;
    })
  }
  // select country code
  changeCountry(code) {
    this.selectedCountryCode = code.country_drc_key;
  }

  openEditDialog(): void {
    const dialogRef = this.dialog.open(EditPersonalModelComponent, {
      data: this.user,
      disableClose: true,

      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'success')
        this._userService.update()
    });
  }

  editPersonalDetails() {
    this.isLoading = true;
    this._myAccountService.editPersonal(this.userForm.value).then((data) => {
    }).catch((error) => {
      if (error.status_code == 608) {
        this._utility.showFailure(this._translocoService.translate("validation_error"))
        for (var key in error.data) {
          this.userForm.get(key).setErrors({ serverType: { message: error.data[key][0] } })
          this.userForm.markAllAsTouched()
        }
      }
    }).finally(() => {
      this.isLoading = false;
    })
  }

  fileChangeEvent(event): void {
    console.log(event)
    if (event.target.value != null) {
      const dialogRef = this.dialog.open(ProfileImageModelComponent, {
        data: event,
        height: "auto",
        width: "500px",
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == 'success') {
          this._userService.update()
        }
      });
    }

  }

  openChangePasswordDialog(): void {
    const dialogRef = this.dialog.open(ChangePasswordModelComponent, {
      data: this.user,
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'success') {
        this._utility.showSuccess(this._translocoService.translate("auth.message.password_changed_successfully"));
        this._router.navigateByUrl('/login?force=true')
      }
      //this._appService.logout();
    });
  }

  removeProfileImage() {
    this._myAccountService.removeProfileImage().then((data) => {
      this._userService.update()
    })
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(0);
    this._unsubscribeAll.complete();
  }
}
