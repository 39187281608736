import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpService } from 'src/app/core/common-service/http.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private _onChangeLanguage$ = new Subject<void>()

  get onChangeLanguage$() {
    return this._onChangeLanguage$;
  }

  constructor(private _httpService: HttpService,) { }

  // Language
  getAllLanguages(page, keyword?, limit?) {
    let params = new HttpParams();
    params = params.set('page', page);
    if (limit)
      params = params.set('limit', limit);
    if (keyword)
      params = params.set('search_key', keyword);
    return new Promise((resolve, reject) => {
      this._httpService.get('admin/attribute/language/getAll?' + params.toString()).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        //this._error.showError(error)
        reject(error)
      })
    })
  }

  // Language
  getLanguageList() {
    let params = new HttpParams();
    params = params.set('is_list', 1);
    return new Promise((resolve, reject) => {
      this._httpService.get('admin/attribute/language/getAll?' + params.toString()).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data.data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  addLanguage(data) {
    return new Promise((resolve, reject) => {
      this._httpService.post('admin/attribute/language/addLanguage', data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        //this._error.showError(error)
        reject(error)
      })
    })
  }

  editLanguage(data) {
    return new Promise((resolve, reject) => {
      this._httpService.post('admin/attribute/language/updateLanguage', data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
          this._onChangeLanguage$.next()
        }
        else {
          reject(data)
        }
      }, (error) => {
        //this._error.showError(error)
        reject(error)
      })
    })
  }

  changLanguageStatus(data) {
    return new Promise((resolve, reject) => {
      this._httpService.post('admin/attribute/language/changeStatus', { language_key: data }).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
          this._onChangeLanguage$.next()
        }
        else {
          reject(data)
        }
      }, (error) => {
        //this._error.showError(error)
        reject(error)
      })
    })
  }

  allocateLanguage(data) {
    return new Promise((resolve, reject) => {
      this._httpService.post('admin/attribute/language/updateAllocation', data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }


}
