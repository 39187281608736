<div class="form-group" *transloco="let t">
  <form [formGroup]="personalForm" (submit)="editPersonalDetails()">
    <div class="control-box">
      <div fxLayout="row" fxLayoutGap="32px" fxLayoutGap.lt-sm="0" fxLayout.lt-sm="column">
        <div fxFlex="1 0 0">
          <mat-form-field appearance="outline">
            <mat-label>{{t('personal_details.title.first_name')}}</mat-label>
            <input formControlName="firstName" matInput type="text">
            <mat-error *ngIf="personalForm.get('firstName').errors?.required">
              {{t('personal_details.validation.please_enter_first_name')}}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="1 0 0">
          <mat-form-field appearance="outline">
            <mat-label>{{t('personal_details.title.last_name')}}</mat-label>
            <input formControlName="lastName" matInput type="text">
            <mat-error
              *ngIf="personalForm.get('lastName').errors?.required">{{t('personal_details.validation.please_enter_last_name')}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

    </div>
    <div class="control-box">
      <mat-form-field appearance="outline">
        <mat-label>{{t('personal_details.title.email')}}</mat-label>
        <input formControlName="email" matInput type="text">
        <mat-error
          *ngIf="personalForm.get('email').errors?.required">{{t('personal_details.validation.please_enter_your_email')}}
        </mat-error>
        <mat-error
          *ngIf="personalForm.get('email').errors?.pattern">{{t('personal_details.validation.please_enter_valid_email')}}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="control-box">
      <mat-form-field appearance="outline">
        <mat-label>Contact No</mat-label>
        <input appOnlyNumbers formControlName="contact" maxlength="10" matInput type="text">
        <mat-error *ngIf="personalForm.get('contact').errors?.required">Please enter contact
        </mat-error>
        <mat-error *ngIf="personalForm.get('contact').errors?.minlength">Please enter valid contact
        </mat-error>
      </mat-form-field>
    </div>
    <div class="control-box">
      <mat-radio-group formControlName="gender">
        <mat-radio-button value="1">Male</mat-radio-button>
        <mat-radio-button value="0">Female</mat-radio-button>
      </mat-radio-group>

    </div>
    <br><br>
    <div class="control-box">
      <mat-form-field appearance="outline">
        <mat-label>Date of birth</mat-label>
        <input matInput formControlName="dob" [max]="maxDate" [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="personalForm.get('dob').errors?.required">Please enter dob
        </mat-error>
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="right-align-controls">
      <button mat-button type="button" (click)="dialogRef.close()">Cancel</button>
      <button mat-button class="success-button" [disabled]="personalForm.invalid || isLoading" cdkFocusInitial>
        <span *ngIf="!isLoading">Update</span>
        <div *ngIf="isLoading" style="padding: 8px;">
          <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
        </div>
      </button>
    </div>
  </form>

</div>