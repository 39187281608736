<mat-sidenav-container class="sidebar-container mat-app-background" [class.dark]="config.scheme === 'dark'"
  *transloco="let t">
  <mat-sidenav class="w-full settings-sidenav" mode="over" #rightDrawer position="end">
    <div class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary">
      <mat-icon class="icon-size-7 text-current">settings</mat-icon>
      <div class="ml-3 text-2xl tracking-tight">{{t('settings')}}</div>
      <button mat-icon-button (click)="rightDrawer.toggle();"
        class="mat-focus-indicator ml-auto mat-icon-button mat-button-base">
        <mat-icon role="img" class="mat-icon notranslate text-current mat-icon-no-color" aria-hidden="true"
          data-mat-icon-type="svg" data-mat-icon-name="x" data-mat-icon-namespace="heroicons_outline">
          close
        </mat-icon>
      </button>
    </div>
    <div class="setting-sidebar dark:bg-gray-800 h-full">
      <div class="text-xl font-medium text-secondary">{{t('scheme')}}</div>
      <div class="grid grid-cols-2 gap-3 justify-items-start mt-6">
        <!-- Dark -->
        <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          [class.ring-2]="config.scheme === 'dark'" (click)=" setScheme('dark')">
          <div class="flex items-center rounded-full overflow-hidden">
            <mat-icon class="icon-size-5">dark_mode</mat-icon>
          </div>
          <div class="flex items-center ml-2 font-medium leading-5"
            [class.text-secondary]="!(config.scheme === 'dark')">
            {{t('dark')}}
          </div>
        </div>
        <!-- Light -->
        <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          [class.ring-2]="config.scheme === 'light'" (click)="setScheme('light')">
          <div class="flex items-center rounded-full overflow-hidden">
            <mat-icon class="icon-size-5">light_mode</mat-icon>
          </div>
          <div class="flex items-center ml-2 font-medium leading-5" [class.text-secondary]="config.scheme === 'dark'">
            {{t('light')}}
          </div>
        </div>
      </div>
      <hr class="my-8">
      <div class="text-xl font-medium text-secondary">{{t('session')}}</div>
      <div class="grid grid-cols-2 gap-3 justify-items-start mt-6">
        <!-- Dark -->
        <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          [class.ring-2]="config.idleTime === 600" (click)="setSession(600)">
          <div class="flex items-center ml-2 font-medium leading-5" [class.text-secondary]="(config.idleTime === 600)">
            10 min
          </div>
        </div>
        <!-- Light -->
        <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          [class.ring-2]="config.idleTime === 900" (click)="setSession(900)">
          <div class="flex items-center ml-2 font-medium leading-5" [class.text-secondary]="config.idleTime === 900">
            90 min
          </div>
        </div>
      </div>

      <hr class="my-8">
      <div class="text-xl font-medium text-secondary">{{t('theme')}}</div>
      <div class="grid grid-cols-3 gap-3 justify-items-start mt-6">
        <ng-container *ngFor="let theme of themes">
          <div
            class="flex w-full items-center px-4 py-3 rounded-full cursor-pointer ring-inset ring-primary bg-gray-100 dark:bg-gray-800"
            [class.ring-2]="config.theme === theme[0]" (click)="setTheme(theme[0])">
            <div>
              <div class="flex-0 w-3 h-3 rounded-full" [style.background-color]="theme[1].primary">
              </div>
            </div>
            <div class="ml-2.5 font-medium leading-5 truncate" [class.text-secondary]="config.theme !== theme[0]">
              {{theme[0] | titlecase}}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content *ngIf="layout=='default'">
    <mat-sidenav-container class="sidebar-container mat-app-background" [class.dark]="config.scheme === 'dark'">
      <mat-sidenav [class.sidebar-fold]="isSidebarFold" [class.sidebar-unfold]="!isSidebarFold" #drawer
        [opened]="!sidebarClosed" [mode]="mode">
        <app-navigation [navigation]="navigation">
          <ng-container navigationHeader>
            <div class="nav-header">
              <div class="company-profile">
                <img
                  [src]="(_sparkConfigService.config$ | async).scheme=='light'?(_sparkConfigService.config$ | async).logo2:(_sparkConfigService.config$ | async).logo2Dark"
                  alt="">
                <h4 *ngIf="!isSidebarFold">{{userData?.user_login.user_role_name.user_role_name}}
                </h4>
              </div>
              <div class="dashboard-info" *ngIf="!isSidebarFold">
                <h3 *ngIf="userData?.first_name">{{userData?.full_name}}</h3>
                <p class="last-login secondary-text">{{t('last_login_at')}}
                  {{userData?.user_login_activity?(userData?.user_login_activity?.created_at
                  | date:'d MMM yy h:mm a'):"now"}}</p>
              </div>
            </div>

          </ng-container>
          <ng-container navigationFooter>
            <div fxLayout="row" fxFlex="1 0 0" class="nav-header-footer" *ngIf="!isSidebarFold">
              <h4 fxFlex="1 0 0" class="secondary-text">{{t('system_version')}} {{appVersion}}</h4>
            </div>
          </ng-container>
        </app-navigation>
      </mat-sidenav>
      <mat-sidenav-content [ngStyle]="{'margin-left.px':contentMargin}">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
        <div class="router-div" style="overflow: auto; height: 92%;">
          <router-outlet *ngIf="true"></router-outlet>
        </div>

      </mat-sidenav-content>
    </mat-sidenav-container>
  </mat-sidenav-content>
  <mat-sidenav-content *ngIf="layout=='empty'">
    <mat-sidenav-container class="sidebar-container mat-app-background" [class.dark]="config.scheme === 'dark'">
      <router-outlet *ngIf="true"></router-outlet>
      <div class="footer-text secondary-text">
        <a href="{{orgURL}}" class="hover:underline" target="blank"> {{t('reserved_spark_technologies')}}
        </a> {{
        currentYear }}<br>
        <ul class="copyright-links">
          <li><a href="{{orgURL}}/policy/privacy-policy" target="blank">{{t('privacy_policy')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/cookie-policy" target="blank">{{t('cookie_policy')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/refund-policy" target="blank">{{t('refund_policy')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/disclaimer" target="blank">{{t('disclaimer')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/terms-of-use" target="blank">{{t('t_of_use')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/terms-condition" target="blank">{{t('t_c')}}</a> | </li>
          <li><a href="{{orgURL}}/policy/terms-of-services" target="blank">{{t('t_of_services')}}</a></li>
        </ul>
      </div>
    </mat-sidenav-container>
  </mat-sidenav-content>

</mat-sidenav-container>

<ng-template #toolbar>
  <mat-toolbar>
    <ng-container *transloco="let t">
      <div class="left">
        <button mat-icon-button (click)="foldSidebar()">
          <mat-icon aria-hidden="false">menu</mat-icon>
        </button>
      </div>
      <div class="right menu-bar flex h-full mr-2 items-center">
        <div>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="languageCtrl" (selectionChange)="setLanguage($event.value.language_code)">
              <mat-select-trigger>
                <div class="flex items-center">
                  <div>
                    <img style="width: 25px" src="assets/icons/{{languageCtrl.value?.icon}}.jpg" alt="">
                  </div>
                  <span class="ml-2 text-white">{{languageCtrl.value.language_name}}</span>
                </div>
              </mat-select-trigger>
              <mat-option *ngFor="let lang of languages" [value]="lang">
                <div class="flex items-center">
                  <div>
                    <img style="width: 25px" src="assets/icons/{{lang?.icon}}.jpg" alt="">
                  </div>
                  <span class="ml-2">{{lang.language_name}}</span>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button class="my-account-menu rounded-none" mat-button [matMenuTriggerFor]="menu">
          <div class="user-circle-avatar">
            <img
              src="{{ userData?.profile_photo_path ? userData?.profile_photo_path : 'assets/placeholder/default.jpg'}}">
            <mat-icon *ngIf="!userData?.profile_photo_path && !userData?.first_name">person</mat-icon>
          </div>
          <h3 class="user-name">{{userData?.first_name}}</h3>
        </button>

        <button class="toolbar-icon mr-2" [matMenuTriggerFor]="notificationMenu" mat-icon-button>
          <mat-icon *ngIf="!unreadCount" matBadgeColor="warn">
            notifications</mat-icon>
          <mat-icon *ngIf="unreadCount" [matBadge]="unreadCount<100?unreadCount:'99+'" matBadgeColor="warn">
            notifications_active</mat-icon>
        </button>

        <mat-menu #menu="matMenu" class="toolbar-menu">
          <span>
            <button mat-menu-item routerLink="/profile">
              <mat-icon class="material-icons-outlined overlay-menu-icon">account_circle</mat-icon>
              {{t('auth.title.my_profile')}}
            </button>
            <button mat-menu-item (click)="changePassword()">
              <mat-icon class="material-icons-outlined overlay-menu-icon">lock</mat-icon>
              {{t('auth.title.change_password')}}
            </button>
          </span>
        </mat-menu>
        <mat-menu #notificationVertMenu="matMenu" class="toolbar-menu">
          <button mat-menu-item *ngIf="unreadCount" (click)="markAllRead()">
            <mat-icon>drafts</mat-icon>
            <span>Mark all as read</span>
          </button>
          <button mat-menu-item (click)="showAllNotifications()">
            <mat-icon>apps</mat-icon>
            <span>See all notifications</span>
          </button>
        </mat-menu>
        <mat-menu #notificationMenu="matMenu" class="mat-app-background notification-tray w-80 h:80" xPosition="before">
          <div (click)="$event.stopPropagation()">
            <!-- Header -->
            <div class=" flex flex-shrink-0 items-center py-4 pr-4 pl-6 bg-primary">
              <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button>
                  <mat-icon class="icon-size-5 text-current">
                    close
                  </mat-icon>
                </button>
              </div>
              <h2 class="text-lg font-medium leading-10 text-white">{{t('notification.notifications')}}
              </h2>
              <!-- <div class="ml-auto">
                                <button mat-icon-button *ngIf="calculateUnread()" (click)="markAllRead()"
                                    [matTooltip]="'Mark all as read'">
                                    <mat-icon class="icon-size-5 text-current">drafts</mat-icon>
                                </button>
                            </div> -->
              <button class="ml-auto" mat-icon-button [matMenuTriggerFor]="notificationVertMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>

            <!-- Content -->
            <div style="max-height: 400px;">
              <div class="relative overflow-y-auto">
                <!-- Notifications -->
                <ng-container *ngFor="let notification of notifications">
                  <div mat-menu-item class="flex group">
                    <!-- Title, description & time -->
                    <div class="flex flex-col flex-auto whitespace-normal">
                      <ng-container *ngIf="notification.notification_title">
                        <div class="font-medium text-base line-clamp-1" [innerHTML]="notification.notification_title">
                        </div>
                      </ng-container>
                      <ng-container *ngIf="notification.notification_body">
                        <div class="line-clamp-2 leading-5" [innerHTML]="notification.notification_body">
                        </div>
                      </ng-container>
                      <div class="mt-1 text-sm leading-none secondary-text">
                        {{notification.created_at | date:'MMM dd, h:mm a'}}
                      </div>
                    </div>
                    <div class="flex flex-row" [ngClass]="{'unread': !notification.notification_status}">
                      <!-- Actions -->
                      <div class="relative flex flex-col ml-2">
                        <!--  Indicator  -->
                        <button class="w-6 h-6 min-h-6" mat-icon-button
                          [matTooltip]="notification.notification_status ? 'Mark as unread' : 'Mark as read'"
                          (click)="markRead(notification.notification_key,notification.notification_status)">
                          <span class="w-2 h-2 rounded-full block m-auto" [ngClass]="{'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': notification.notification_status==1,
                                                            'bg-primary': notification.notification_status==0}"></span>
                        </button>
                        <button class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100 leading-none mt-2"
                          mat-icon-button [matTooltip]="'Remove'"
                          (click)="removeNotification(notification.notification_key)">
                          <mat-icon class="icon-size-4 mr-0"> close
                          </mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div *ngIf="notifications?.length>0" class="p-4 text-center">
                  <span class="cursor-pointer text-blue-500" (click)="showAllNotifications()">See
                    all</span>
                </div>
                <!-- No notifications -->
                <ng-container *ngIf="!notifications || !notifications.length">
                  <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
                      <mat-icon class="text-black">notifications</mat-icon>
                    </div>
                    <div class="mt-5 text-xl font-medium tracking-tight">
                      {{t('notification.no_notifications')}}</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">
                      {{t('notification.when_you_have_notifications_they_will_appear_here')}}
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </mat-menu>

        <button class="toolbar-icon mr-2" [matTooltip]="t('settings')" (click)="openSettings()" mat-icon-button>
          <mat-icon [inline]="true" class="icon-size-6 text-white">settings
          </mat-icon>
        </button>

        <button class="toolbar-icon mr-2" [matTooltip]="t('auth.title.logout')" (click)="logout()" mat-icon-button>
          <mat-icon [inline]="true" class="icon-size-6 text-white">logout
          </mat-icon>
        </button>
      </div>
    </ng-container>
  </mat-toolbar>
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</ng-template>