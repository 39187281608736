import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ImageCropperModelComponent } from './image-cropper-modal/image-cropper-modal.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PasswordConfirmModalComponent } from './password-confirm-modal/password-confirm-modal.component';
import { FilterGroupComponent } from './filter-group/filter-group.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { EditorComponent } from './editor/editor.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';



@NgModule({
  declarations: [
    ImageCropperModelComponent,
    PasswordConfirmModalComponent,
    FilterGroupComponent,
    EditorComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ImageCropperModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    CKEditorModule,
    MatButtonToggleModule
  ],
  exports: [
    FilterGroupComponent,
    EditorComponent
  ]
})
export class CommonComponentModule { }
