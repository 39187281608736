// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  production: false,
  APIUrl: 'https://thesparktech.sparktechdev.org/api/',
  orgURL: 'https://thesparktech.sparktechdev.org',
  passphrase: '{(<^All|/\|eB@pp$^>)&[~$T#25218~]}',
  isDebug: false,
  firebaseConfig: {
    apiKey: "AIzaSyCC74TVyn0gEwBS8KC7wL66hnlRnH3U_5A",
    authDomain: "webtest-1b931.firebaseapp.com",
    projectId: "webtest-1b931",
    storageBucket: "webtest-1b931.appspot.com",
    messagingSenderId: "360830017288",
    appId: "1:360830017288:web:0334c8e7dc63670dabd6bd",
    measurementId: "G-JHZC8ERRT1"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
