import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { HttpService } from '../common-service/http.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private timerSubscription: Subscription;
  private _notifications: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private _httpService: HttpService,) { }

  get notifications$(): Observable<any> {
    return this._notifications.asObservable();
  }

  start() {
    if (!this.timerSubscription || this.timerSubscription?.closed) {
      this._notifications.next([])
      this.timerSubscription = timer(0, 10000).pipe(
        map(() => {
          let params = new HttpParams();
          params = params.set('page', 1);
          params = params.set('limit', 10);
          params = params.set('notification_status', 0);
          this._httpService.get('common/notification/getAll?' + params).subscribe((data: any) => {
            if (data.status_code == 200) {
              this._notifications.next(data.data)
            }
            else
              this.stop()
          }, (error) => {
            this.stop()
          })

        })
      ).subscribe();
    }
  }

  stop() {
    this.timerSubscription.unsubscribe()
  }
}
