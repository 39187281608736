<div class="loader" *ngIf="isLoading">
    <mat-spinner [diameter]="50" style="margin: auto;"></mat-spinner>
</div>
<image-cropper style="padding: 0;" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
    [aspectRatio]="1/1" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
    [roundCropper]="true" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>

<div mat-dialog-actions align="end" *transloco="let t">
    <button mat-button type="button" (click)="dialogRef.close()" [disabled]="isUploading"
        style="margin-right: 10px;">{{t('cancel')}}</button>
    <mat-spinner *ngIf="isUploading" [diameter]="30"></mat-spinner>
    <button mat-button class="success-button" (click)="setImage()" *ngIf="!isUploading"
        cdkFocusInitial>{{t('auth.title.set_profile')}}</button>
</div>