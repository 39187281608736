import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatchMediaService } from '../../@spark/services/match-media.service';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppService } from '../app.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SparkNavigationService } from 'src/@spark/components/navigation/navigation.service';
import { NavigationService } from '../core/navigation/navigation.service';
import { UserService } from '../core/user/user.service';
import { AuthService } from '../core/auth/auth.service';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { DOCUMENT, formatDate } from '@angular/common';
import { Subject } from 'rxjs';
import { Idle } from '@ng-idle/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { UtilityService } from '../core/common-service/utility.service';
import { getToken, Messaging, onMessage } from '@angular/fire/messaging';
import { ChangePasswordModelComponent } from '../auth-pages/my-account/change-password-model/change-password-model.component';
import { TailwindService } from 'src/@spark/services/tailwind.service';
import { LayoutService } from './layout.service';
import { NotificationService } from '../core/messaging/notification.service';
import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import { FormControl } from '@angular/forms';
import { LanguageService } from '../components/attribute/language/language.service';
import { ManageMenuService } from '../components/accessibility/manage-menu/manage-menu.service';
import { AllNotificationsComponent } from './all-notifications/all-notifications.component';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  config;
  isSidebarFold = false;
  sidebarClosed = false;
  contentMargin = 285;
  isDarkMode: boolean = false;
  appVersion = environment.appVersion;
  mode = 'side';
  userData;
  isLoading;
  navigation = []
  scheme: 'dark' | 'light';
  layout;
  notifications: any[] = [];
  currentYear = new Date().getFullYear()
  orgURL = environment.orgURL
  unreadCount = 0;
  //nextYear = new Date().setFullYear(this.currentYear + 1)

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  theme: any;
  language: any;
  themes: [string, unknown][];
  languages: any[] = [];
  languageCtrl: FormControl = new FormControl('');
  constructor(
    private _sparkNavigationService: SparkNavigationService,
    private _navigationService: NavigationService,
    private _matchMedia: MatchMediaService,
    private _mediaObserver: MediaObserver,
    private _router: Router,
    public _sparkConfigService: SparkConfigService,
    private _authService: AuthService,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _utility: UtilityService,
    private _translocoService: TranslocoService,
    private _tailwindConfigService: TailwindService,
    private _layoutService: LayoutService,
    private _notificationService: NotificationService,
    public dialog: MatDialog,
    private idle: Idle,
    private languageService: LanguageService,
    @Inject(DOCUMENT) private _document: any,
    private overlayContainer: OverlayContainer) {
    this._sparkConfigService.config$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
        this.config = config;
        this.scheme = config.scheme;
        this.layout = config.layout;
        this.theme = config.theme;
        this.language = config.language
        this._updateScheme();
        this._updateLayout();
        this._updateTheme();
      });

    this._navigationService.navigation$.subscribe((data: any) => {
      this.navigation = data
    })
  }

  @ViewChild('drawer') drawer: MatDrawer
  @ViewChild('rightDrawer') rightDrawer: MatDrawer

  ngOnInit() {
    this.getLanguages()

    this.languageService.onChangeLanguage$.subscribe(() => {
      this.getLanguages()
    })

    // Get the themes
    this._tailwindConfigService.tailwindConfig$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((config) => {
        this.themes = Object.entries(config.themes);
      });

    this._router.events.subscribe(() => {
      this._updateLayout();
      if (this.drawer) {
        const isActive = this._mediaObserver.isActive('lt-md');
        if (isActive) {
          this.drawer.close();
        }
      }
    });

    this._notificationService.notifications$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((data) => {
        if (data) {
          this.notifications = data.data
          this.unreadCount = data.total
        }
        else {
          this.notifications = []
          this.unreadCount = 0
        }
      })


    this._matchMedia.onMediaChange.subscribe((data) => {
      // Get the active status
      const isActive = this._mediaObserver.isActive('lt-md');
      if (isActive) {
        this.sidebarClosed = true;
        this.mode = 'over';
      }
      else {
        this.sidebarClosed = false;
        this.mode = 'side';
      }
    })

    this._userService.user$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((data) => {
        this.userData = data
      })
  }

  getLanguages() {
    this._layoutService.getLanguages().then((data: any) => {
      this.languages = data
      let langArray: any[] = []
      this.languages.forEach((lang) => {
        langArray.push({
          id: lang.language_code,
          label: lang.language_name
        })
      })
      this._translocoService.setAvailableLangs(langArray)
      let lang = this.languages.find((la) => la.language_code == this.language.toString())
      this._translocoService.setActiveLang(lang.language_code)
      this.languageCtrl.patchValue(lang)
    })
  }

  logout() {
    this._authService.signOut().then((data) => {
      this._router.navigate(['login']);
    });
  }

  getNotifications() {
    this._layoutService.getNotifications(1, 0).then((data: any) => {
      if (data) {
        this.notifications = data.data
        this.unreadCount = data.total
      }
      else {
        this.notifications = []
        this.unreadCount = 0
      }
    })
  }

  calculateUnread() {
    if (this.notifications && this.notifications.length > 0)
      return this.notifications.filter((val) => !val.notification_status).length
    else
      return 0
  }

  markAllRead() {
    this._layoutService.markAllRead().then((data) => {
      this.getNotifications()
    })
  }

  markRead(key, notificationStatus) {
    this._layoutService.markRead(key, notificationStatus).then((data) => {
      this.getNotifications()
    })
  }

  showAllNotifications() {
    const dialogRef = this.dialog.open(AllNotificationsComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      // if (result == 'success')
      //   this.getSubscriptions(0)
    });
  }

  removeNotification(key) {
    this._layoutService.removeNotification(key).then((data) => {
      this.getNotifications()
    })
  }

  foldSidebar() {
    const isActive = this._mediaObserver.isActive('gt-sm')
    if (isActive) {
      this.isSidebarFold = !this.isSidebarFold;
      this._sparkNavigationService.onSidebarFold.next(this.isSidebarFold)
    }
    else {
      this.drawer.toggle();
    }
    if (this.isSidebarFold) {
      this.contentMargin = 70;
    }
    else {
      this.contentMargin = 270;
    }
  }

  openSettings() {
    this.rightDrawer.toggle();
  }

  setLanguage(language): void {
    this._sparkConfigService.config = { language };
    window.location.reload()
  }

  setScheme(scheme): void {
    this._sparkConfigService.config = { scheme };
  }

  setTheme(theme): void {
    this._sparkConfigService.config = { theme };

  }

  setSession(idleTime): void {
    this._sparkConfigService.config = { idleTime };
    this.idle.watch()
  }

  private _updateScheme(): void {
    this._document.body.classList.remove('light', 'dark');
    if (this.scheme == 'dark' ||
      this.scheme == 'light') {
      this._document.body.classList.add(this.scheme);
      this.overlayContainer.getContainerElement().classList.add(this.scheme);
    }
    else {
      this._document.body.classList.add('light');
      this.overlayContainer.getContainerElement().classList.remove(this.scheme);
    }
  }

  private _updateLayout(): void {

    if (
      this.config.layout == 'empty' ||
      this.config.layout == 'default') {
      let route = this._activatedRoute;
      while (route.firstChild) {
        route = route.firstChild;
      }
      this.layout = this.config.layout;
      const layoutFromQueryParam = (route.snapshot.queryParamMap.get('layout'));
      if (layoutFromQueryParam) {
        this.layout = layoutFromQueryParam;
        if (this.config) {
          this.config.layout = layoutFromQueryParam;
        }
      }

      // 3. Iterate through the paths and change the layout as we find
      // a config for it.
      //
      // The reason we do this is that there might be empty grouping
      // paths or componentless routes along the path. Because of that,
      // we cannot just assume that the layout configuration will be
      // in the last path's config or in the first path's config.
      //
      // So, we get all the paths that matched starting from root all
      // the way to the current activated route, walk through them one
      // by one and change the layout as we find the layout config. This
      // way, layout configuration can live anywhere within the path and
      // we won't miss it.
      //
      // Also, this will allow overriding the layout in any time so we
      // can have different layouts for different routes.

      const paths = route.pathFromRoot;
      paths.forEach((path) => {
        // Check if there is a 'layout' data
        if (path.routeConfig && path.routeConfig.data && path.routeConfig.data.layout) {
          // Set the layout
          this.layout = path.routeConfig.data.layout;
        }
      });
    }
    else {
      this.layout = 'default';
    }
  }

  private _updateTheme(): void {
    // Find the class name for the previously selected theme and remove it
    this._document.body.classList.forEach((className: string) => {
      if (className.startsWith('theme-')) {
        this._document.body.classList.remove(className, className.split('-')[1]);
      }
    });

    // Add class name for the currently selected theme
    this._document.body.classList.add(`theme-${this.theme}`);
  }

  changePassword() {
    const dialogRef = this.dialog.open(ChangePasswordModelComponent, {
      data: this.userData,
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'success') {
        this._utility.showSuccess(this._translocoService.translate("auth.message.password_changed_successfully"));
        this._router.navigateByUrl('/login?force=true')
      }
      //this._appService.logout();
    });
  }
}
