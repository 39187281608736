import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateCountdown'
})
export class DateCountdownPipe implements PipeTransform {

  transform(value, warningLimit = 8): unknown {
    var given = moment(new Date(value), "YYYY-MM-DD");
    var current = moment().startOf('day');
    var months = given.diff(current, 'months');
    current.add(months, 'months');
    var days = given.diff(current, 'days');
    if (months >= 0 && days >= 0) {
      if (months == 0) return "<span class='" + (days <= warningLimit ? 'text-red-500' : '') + "'>" + days + " days</span>";
      else
        return months + "M " + days + "D";
    }
    else
      return "Nill"
  }

}
