import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { LocationService } from 'src/app/core/location/location.service';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isLoading = false;
  hide = true;
  redirectUrl: any = '/';
  locationInfo: any;

  constructor(
    private _toastr: ToastrService,
    public _configService: SparkConfigService,
    private _fb: FormBuilder,
    private _authService: AuthService,
    private router: Router,
    private _locationService: LocationService,
    private _utilityService: UtilityService,
    private _translocoService: TranslocoService,
    private _activeRoute: ActivatedRoute
  ) {
    let params = this._activeRoute.snapshot.queryParams;
    if (params['redirectURL']) {
      this.redirectUrl = params['redirectURL'];
    }

    this._activeRoute.queryParams.subscribe(params => {
      if (params['verified'] && atob(params['verified']) == '1') {
        Swal.fire({
          heightAuto: false,
          icon: 'success',
          title: _translocoService.translate("auth.message.your_mail_verified_successfully")
        }).then((result: SweetAlertResult) => {
          this.router.navigate([], {
            queryParams: {
              'verified': null
            },
            queryParamsHandling: 'merge',
            replaceUrl: true
          })
        })
      }
      else if (params['verified'] && atob(params['verified']) == '0') {
        Swal.fire({
          heightAuto: false,
          icon: 'error',
          confirmButtonText: _translocoService.translate("actions.ok"),
          title: _translocoService.translate("auth.message.unable_to_verify_email")
        }).then((result: SweetAlertResult) => {
          this.router.navigate([], {
            queryParams: {
              'verified': null
            },
            queryParamsHandling: 'merge',
            replaceUrl: true
          })
        })
      }
    });
  }

  ngOnInit() {
    this.loginForm = this._fb.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required],
      'latitude': [''],
      'longitude': [''],
    })

    this._locationService.getPosition().then((result) => {
      this.locationInfo = result
      this.loginForm.get("longitude").patchValue(this.locationInfo.longitude)
      this.loginForm.get("latitude").patchValue(this.locationInfo.latitude)
    })
  }

  login() {
    this.isLoading = true;
    this._authService.signIn(this.loginForm.value).subscribe((data) => {
      this.isLoading = false;
      if (data.success) {
        this.isLoading = false;
        this.router.navigateByUrl(this.redirectUrl)
      }
      else
        this._utilityService.showFailure(data.message)
    },
      ((e) => {
        console.log(e);
        this.isLoading = false;
      })
    )
  }


}
