<ng-container *transloco="let t">
    <div mat-dialog-title class="flex justify-between items-center">
        <h1> All notifications</h1>
        <div *ngIf="notifications.length>0" class="secondary-text font-normal text-base cursor-pointer"
            (click)="removeAllNotifications()">
            <mat-icon>close</mat-icon> <span class="ml-1 align-middle">Clear all</span>
        </div>
    </div>

    <div mat-dialog-content>
        <ng-container *ngFor="let notification of notifications;let i=index">
            <div mat-menu-item class="flex group">
                <!-- Title, description & time -->
                <div class="flex flex-col flex-auto whitespace-normal">
                    <ng-container *ngIf="notification.notification_title">
                        <div class="font-medium text-base line-clamp-1" [innerHTML]="notification.notification_title">
                        </div>
                    </ng-container>
                    <ng-container *ngIf="notification.notification_body">
                        <div class="line-clamp-2 leading-5" [innerHTML]="notification.notification_body">
                        </div>
                    </ng-container>
                    <div class="mt-1 text-sm leading-none secondary-text">
                        {{notification.created_at | date:'MMM dd, h:mm a'}}
                    </div>
                </div>
                <div class="flex flex-row" [ngClass]="{'unread': !notification.notification_status}">
                    <!-- Actions -->
                    <div class="relative flex flex-col ml-2">
                        <!--  Indicator  -->
                        <button class="w-6 h-6 min-h-6" mat-icon-button
                            [matTooltip]="notification.notification_status ? 'Mark as unread' : 'Mark as read'"
                            (click)="markRead(notification.notification_key,notification.notification_status,i)">
                            <span class="w-2 h-2 rounded-full block m-auto" [ngClass]="{'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': notification.notification_status==1,
                                'bg-primary': notification.notification_status==0}"></span>
                        </button>
                        <button class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100 leading-none mt-2"
                            mat-icon-button [matTooltip]="'Remove'"
                            (click)="removeNotification(notification.notification_key,i)">
                            <mat-icon class="icon-size-4 mr-0"> close
                            </mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <div *ngIf="!isLoading && !lastPage && notifications.length>0" class="p-4 text-center text-blue-500">
            <span class="cursor-pointer" (click)="loadMore()">View more</span>
        </div>
        <div *ngIf="!isLoading && notifications.length==0" class="p-4 text-center secondary-text">
            <span>No notifications found!</span>
        </div>
        <div *ngIf="isLoading" class="p-4 text-center secondary-text">
            Loading...
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button type="button" class="add-round-button" (click)="dialogRef.close()">{{t('cancel')}}</button>
    </div>
</ng-container>