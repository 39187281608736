export interface config {
  navbar: {
    color: any;
    hidden: boolean;
  };
  toolbar: {
    color: any;
    hidden: boolean;
  },
  layout,
  logo1,
  logo1Dark,
  logo2,
  logo2Dark,
  theme,
  scheme,
  idleTime,
  language
}
export const sparkConfig: config = {
  navbar: {
    color: '',
    hidden: false,
  },
  toolbar: {
    color: '',
    hidden: false,
  },
  logo1: 'assets/logo/logo_gradient02.svg',
  logo1Dark: 'assets/logo/logo_white.svg',
  logo2: 'assets/logo/iconmark_gradient02.svg',
  logo2Dark: 'assets/logo/iconmark_white.svg',
  theme: 'default',
  layout: 'default',
  scheme: 'light',
  idleTime: 600,
  language: 'en-in'
}
