<div class="flex gap-2 items-center flex-wrap" *transloco="let t">
  <div>
    <mat-button-toggle-group [formControl]="filterCtrl" (change)="filterCtrl.value?change(0):null">
      <mat-button-toggle [value]="1">{{t('filters.title.today')}}</mat-button-toggle>
      <mat-button-toggle [value]="2">{{t('filters.title.yesterday')}}</mat-button-toggle>
      <mat-button-toggle [value]="3">3M</mat-button-toggle>
      <mat-button-toggle [value]="4">6M</mat-button-toggle>
      <mat-button-toggle [value]="5">9M</mat-button-toggle>
      <mat-button-toggle [value]="6">1Y</mat-button-toggle>
      <mat-button-toggle [value]="7">3Y</mat-button-toggle>
      <mat-button-toggle [value]="8">5Y</mat-button-toggle>
      <mat-button-toggle [value]="0">{{t('filters.title.custom')}}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="flex gap-2 items-center" *ngIf="!filterCtrl.value">
    <mat-form-field class="filter-form-field" appearance="outline">
      <mat-label>{{t('filters.title.from')}}</mat-label>
      <input matInput [formControl]="from" [max]="today" [matDatepicker]="picker">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="filter-form-field" appearance="outline">
      <mat-label>{{t('filters.title.to')}}</mat-label>
      <input matInput [formControl]="to" [min]="from.value" [max]="range" [matDatepicker]="picker2">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
    <button mat-button [disabled]="isLoading" (click)="change(1)"
      class="success-button px-3">{{t('filters.title.search')}}</button>
  </div>
</div>