import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpService } from 'src/app/core/common-service/http.service';

@Injectable({
  providedIn: 'root'
})
export class ManageMenuService {
  private _onChangeMenu$ = new Subject<void>()

  get onChangeMenu$() {
    return this._onChangeMenu$;
  }

  constructor(private _httpService: HttpService,) { }

  // Menu
  getMenus() {
    return new Promise((resolve, reject) => {
      this._httpService.get('admin/attribute/menu/getMenus').subscribe((data: any) => {
        resolve(data.data)
      }, (error) => {
        reject(error)
      })
    })
  }

  addMenu(data) {
    return new Promise((resolve, reject) => {
      this._httpService.post('admin/attribute/menu/addMenu', data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
          this._onChangeMenu$.next()
        }
        else {
          reject(data)
        }

      }, (error) => {
        //this._error.showError(error)
        reject(error)
      })
    })
  }

  editMenu(data) {
    return new Promise((resolve, reject) => {
      this._httpService.post('admin/attribute/menu/updateMenu', data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
          this._onChangeMenu$.next()
        }
        else {
          reject(data)
        }
      }, (error) => {
        //this._error.showError(error)
        reject(error)
      })
    })
  }

  updateMenu(data) {
    return new Promise((resolve, reject) => {
      this._httpService.post('admin/attribute/menu/updateOrder', data).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
          this._onChangeMenu$.next()
        }
        else {
          reject(data)
        }
      }, (error) => {
        //this._error.showError(error)
        reject(error)
      })
    })
  }

  changeMenuStatus(data) {
    return new Promise((resolve, reject) => {
      this._httpService.post('admin/attribute/menu/changeStatus', { menu_key: data }).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
          this._onChangeMenu$.next()
        }
        else {
          reject(data)
        }
      }, (error) => {
        //this._error.showError(error)
        reject(error)
      })
    })
  }

  deleteMenu(data) {
    return new Promise((resolve, reject) => {
      this._httpService.post('admin/attribute/menu/deleteMenu', { menu_key: data }).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data)
          this._onChangeMenu$.next()
        }
        else {
          reject(data)
        }
      }, (error) => {
        //this._error.showError(error)
        reject(error)
      })
    })
  }
}
