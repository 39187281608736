import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BytesConvertorPipe } from './bytes-convertor.pipe';
import { DateCountdownPipe } from './date-countdown.pipe';
import { FromSnakecasePipe } from './from-snakecase.pipe';
import { DateFromMillisecondsPipe } from './date-from-milliseconds.pipe';
import { RemoveQuotesPipe } from './remove-quotes.pipe';
import { FindBusinessDocumentPipe } from './find-business-document.pipe';
import { CheckFieldPipe } from './check-field.pipe';
import { FindInArrayPipe } from './find-in-array.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { FindIndexInArrayPipe } from './find-index-array.pipe';
import { ArrayToStringPipe } from './array-to-string.pipe';



@NgModule({
  declarations: [
    BytesConvertorPipe,
    DateCountdownPipe,
    DateFromMillisecondsPipe,
    FromSnakecasePipe,
    RemoveQuotesPipe,
    FindBusinessDocumentPipe,
    CheckFieldPipe,
    FindInArrayPipe,
    SafeHtmlPipe,
    FindIndexInArrayPipe,
    ArrayToStringPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BytesConvertorPipe,
    DateCountdownPipe,
    DateFromMillisecondsPipe,
    FromSnakecasePipe,
    RemoveQuotesPipe,
    FindBusinessDocumentPipe,
    CheckFieldPipe,
    FindInArrayPipe,
    SafeHtmlPipe,
    FindIndexInArrayPipe,
    ArrayToStringPipe
  ]
})
export class PipesModule { }
