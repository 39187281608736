<div class="middle-box mat-app-background">
  <img class="logo" width="300"
    [src]="(_configService.config$ | async).scheme=='light'?(_configService.config$ | async).logo1:(_configService.config$ | async).logo1Dark"
    alt="">

  <div class="form-group" *transloco="let t">
    <form [formGroup]="loginForm" (submit)="login()">
      <div class="control-box">
        <mat-form-field appearance="outline">
          <mat-label>{{t('auth.title.username')}}</mat-label>
          <input formControlName="username" matInput type="text" appLowercase>
          <mat-error
            *ngIf="loginForm.get('username').errors?.required">{{t('auth.validation.please_enter_your_username')}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="control-box" style="flex-direction: column;">
        <mat-form-field appearance="outline">
          <mat-label>{{t('auth.title.password')}}</mat-label>
          <input formControlName="password" [type]="hide ? 'password' : 'text'" matInput>
          <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
          </button>
          <mat-error
            *ngIf="loginForm.get('password').errors?.required">{{t('auth.validation.please_enter_your_password')}}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- <label for="" class="forget-text secondary-text" routerLink="/forget">{{t('auth.title.forget_password')}}</label> -->
      <div style="clear: both;"></div>
      <div class="control-box ">
        <button mat-button class="w-full bg-green-700 text-white add-round-button"
          [disabled]="loginForm.invalid || isLoading">
          <span *ngIf="!isLoading">
            <mat-icon class="mr-2 icon-size-5">login</mat-icon>{{t('auth.title.login')}}
          </span>
          <div *ngIf="isLoading" style="padding: 8px;">
            <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
          </div>
        </button>
      </div>
      <!-- <div class="control-box right-align-controls login-text secondary-text">
                <p routerLink="/register">{{t('auth.title.dont_have_an_account_register_here')}}</p>
            </div> -->
    </form>
  </div>
</div>