import { Injectable, Optional } from '@angular/core';
import { Messaging, onMessage, getToken, } from '@angular/fire/messaging';
import { BehaviorSubject, EMPTY, from, Observable, pipe } from 'rxjs';
import { UtilityService } from '../common-service/utility.service';
@Injectable({
  providedIn: 'root'
})

export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  message$: Observable<any> = EMPTY;
  token;

  get token$(): Observable<any> {
    return this.token;
  }
  constructor(
    @Optional() messaging: Messaging,
    private _utility: UtilityService
  ) {
    if (Notification.permission == 'denied') {
      Notification.requestPermission()
    }
    if (messaging && Notification.permission) {
      navigator.serviceWorker.register('firebase-messaging-sw.js', { type: 'module', scope: '__' }).
        then(serviceWorkerRegistration =>
          getToken(messaging, {
            serviceWorkerRegistration
          }).then((token) => {
            console.log(token);

            this.token = token
          }).catch((error) => {
            _utility.showWarning("Unable to get permission to notify!")
            console.error('Unable to get permission to notify.', error);
          })
        )

      navigator.serviceWorker.onmessage = (event) => {
        if (event.data.data) {
          //console.log(event.data.data)
          this.currentMessage.next(event.data.data);
        }

      };
 

      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
      });

    }


  }


}
