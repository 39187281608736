import { HttpClient, HttpParams, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  baseUrl = environment.APIUrl;
  config;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private _http: HttpClient,
    private _sparkConfigService: SparkConfigService,) {
    this._sparkConfigService.config$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
        this.config = config
        this.baseUrl = environment.APIUrl + config.language + "/"
      });
  }

  get(path) {
    return this._http.get(this.baseUrl + path);
  }

  download(path) {
    return this._http.get(this.baseUrl + path, { responseType: 'blob' });
  }


  /**
   * Post api for downloading files from server
   * @param path Api endpoint
   * @param data
   * @returns
   */
  downloadGet(path) {
    return this._http.get(this.baseUrl + path, {
      responseType: 'blob',
      observe: 'response'
    }).pipe(map((res: any) => {
      if (res.body) {
        if (res.headers.get('Content-Type') == "application/json") {
          return null;
        }
        else {
          let data = {
            data: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('File-Name')
          }
          return data;
        }
      }
      return null
    }
    ))
  }


  getWithAuth(path) {
    return this._http.get(this.baseUrl + path, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') } });
  }

  post(path, data, skip?) {
    return this._http.post(this.baseUrl + path, data, { headers: skip ? { 'skip': 'true' } : null });
  }

  postWithAuth(path, data) {
    return this._http.post(this.baseUrl + path, data, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') } });
  }
}
