<mat-horizontal-stepper [linear]="true" #stepper *transloco="let t">
  <mat-step [stepControl]="checkExistingPasswordFormGroup" label="Check existing">
    <div class="form">
      <form [formGroup]="checkExistingPasswordFormGroup">
        <div class="control-box">
          <mat-form-field appearance="outline">
            <mat-label>{{t('auth.title.existing_password')}}</mat-label>
            <input formControlName="password" placeholder="Enter existing password" matInput type="password">
            <mat-error *ngIf="checkExistingPasswordFormGroup.get('password').errors?.required">
              {{t('auth.validation.please_enter_your_password')}}
            </mat-error>
            <mat-error *ngIf="checkExistingPasswordFormGroup.get('password').errors?.incorrect">
              {{t('auth.validation.please_enter_correct_password')}}
            </mat-error>
            <mat-error *ngIf="checkExistingPasswordFormGroup.get('password').errors?.checkError">
              {{t('auth.message.error_while_checking_existing_password')}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="right-align-controls">
          <button class="success-button add-round-button" type="button" (click)="checkPassword()"
            [disabled]="checkExistingPasswordFormGroup.invalid || isLoading" mat-button>
            <span *ngIf="!isLoading">{{t('check')}}</span>
            <div *ngIf="isLoading" style="padding: 8px;">
              <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
            </div>
          </button>
        </div>
      </form>
    </div>

  </mat-step>
  <mat-step [stepControl]="changePasswordFormGroup" label="Change password">
    <form [formGroup]="changePasswordFormGroup" autocomplete="off">
      <div class="control-box">
        <mat-form-field appearance="outline">
          <mat-label>{{t('auth.title.new_password')}}</mat-label>
          <input formControlName="password" matInput type="password" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
            (input)="checkPasswordStrength($event)" (focus)="checkPasswordStrength($event)" (blur)="isOpen=false">
          <mat-error
            *ngIf="changePasswordFormGroup.get('password').errors?.required">{{t('auth.validation.please_enter_new_password')}}
          </mat-error>
          <mat-error *ngIf="changePasswordFormGroup.get('password').errors?.pattern">
            {{t('auth.validation.password_does_not_match_with_conditions')}}
          </mat-error>
        </mat-form-field>
      </div>
      <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen">
        <ul class="validation-list">
          <li *ngFor="let validation of _utility.validationList" [class.verified]="validation.status"
            [class.unverified]="!validation.status">
            <mat-icon>check_circle</mat-icon> {{validation.name}}
          </li>
        </ul>
      </ng-template>
      <div class="control-box">
        <mat-form-field appearance="outline">
          <mat-label>{{t('auth.title.confirm_new_password')}}</mat-label>
          <input formControlName="confirmPassword" matInput type="password">
          <mat-error *ngIf="changePasswordFormGroup.get('confirmPassword').errors?.required">
            {{t('auth.validation.please_enter_confirm_password')}}
          </mat-error>
          <mat-error *ngIf="changePasswordFormGroup.get('confirmPassword').errors?.mustMatch">
            {{t('auth.validation.passwords_do_not_match')}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="right-align-controls">
        <button mat-button matStepperNext class="success-button add-round-button"
          [disabled]="changePasswordFormGroup.invalid || isLoading" (click)="changePassword()">
          <span *ngIf="!isLoading">{{t('auth.title.change_password')}}</span>
          <div *ngIf="isLoading" style="padding: 8px;">
            <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
          </div>
        </button>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>