import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { MyAccountService } from '../my-account.service';

@Component({
  selector: 'app-change-password-model',
  templateUrl: './change-password-model.component.html',
  styleUrls: ['./change-password-model.component.scss']
})
export class ChangePasswordModelComponent implements OnInit {
  checkExistingPasswordFormGroup: FormGroup;
  changePasswordFormGroup: FormGroup;
  isLoading = false;
  isOpen = false;
  @ViewChild('stepper') private myStepper: MatStepper;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _myAccountService: MyAccountService,
    public _utility: UtilityService) { }

  ngOnInit(): void {
    this.checkExistingPasswordFormGroup = this._formBuilder.group({
      password: ['', Validators.required]
    });
    this.changePasswordFormGroup = this._formBuilder.group({
      password: ['', [Validators.required, , Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this._utility.MustMatch('password', 'confirmPassword')
    });
  }

  // check password conditions
  checkPasswordStrength(event) {
    if (event.target.value.length > 0) {
      this.isOpen = true;
    }
    // check for digits
    this._utility.validationList[0].status = /\d/.test(event.target.value);
    // check for min 8 characters
    this._utility.validationList[1].status = event.target.value.length >= 8;
    // check at least one lower case
    this._utility.validationList[2].status = /[a-z]/.test(event.target.value);
    // check at least one upper case
    this._utility.validationList[3].status = /[A-Z]/.test(event.target.value);
    // check at least one special character
    this._utility.validationList[4].status = /[#?!@$%^&*-]/.test(event.target.value);
  }

  checkPassword() {
    this.isLoading = true;
    this._myAccountService.checkPassword(this.checkExistingPasswordFormGroup.get('password').value).then(
      (data: any) => {
        if (data.success == 1) {
          this.myStepper.next()
          this.checkExistingPasswordFormGroup.get('password').setErrors({ 'incorrect': false })
          this.checkExistingPasswordFormGroup.get('password').setValue('')
        } else {
          this.checkExistingPasswordFormGroup.get('password')?.setErrors({ 'incorrect': true })
        }
      }).catch(error => {
        this.checkExistingPasswordFormGroup.get('password').setErrors({ 'checkError': true })
      }).finally(() => {
        this.isLoading = false;
      })
  }

  changePassword() {
    this.isLoading = true;
    this._myAccountService.changePassword(this.changePasswordFormGroup.get('password').value).then(
      (data) => {
        this.dialogRef.close('success')
        this._utility.clearSession()
      }).catch(error => {
      }).finally(() => {
        this.isLoading = false;
      })
  }

}
