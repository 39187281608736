import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/messaging/notification.service';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-all-notifications',
  templateUrl: './all-notifications.component.html',
  styleUrls: ['./all-notifications.component.scss']
})
export class AllNotificationsComponent implements OnInit {
  notifications: any[] = [];
  isLoading: boolean;
  hasError: boolean;
  lastPage: boolean = false;
  pagination = {
    length: 0,
    page: 0,
    size: 10
  }
  constructor(
    public dialogRef: MatDialogRef<AllNotificationsComponent>,
    private _layoutService: LayoutService,
  ) { }

  ngOnInit(): void {
    this.getNotifications(0)
  }

  getNotifications(page, size = this.pagination.size, status = false) {
    this.isLoading = true;
    this._layoutService.getNotifications(page + 1, null, size).then((data: any) => {
      this.isLoading = false
      if (data) {
        this.notifications.push(...data.data)
        this.pagination = {
          length: data.total,
          page: page,
          size: size
        }
        if (data.to == data.total) {
          this.lastPage = true
        }
      }
      else {
        this.notifications = []
        this.pagination = {
          length: 0,
          page: 0,
          size: size
        }
      }
    }).catch((error) => {
      this.hasError = true
      this.isLoading = false
    }).finally(() => {
      this.isLoading = false
    })
  }

  loadMore() {
    this.getNotifications(this.pagination.page + 1, 10)
  }

  markRead(key, notificationStatus, index) {
    this._layoutService.markRead(key, notificationStatus).then((data) => {
      this.notifications[index].notification_status = !notificationStatus
      //this.getNotifications(this.pagination.page)
    })
  }

  removeNotification(key, index) {
    this._layoutService.removeNotification(key).then((data) => {
      this.notifications.splice(index, 1)
    })
  }

  removeAllNotifications() {
    this._layoutService.removeAllNotification().then((data) => {
      this.getNotifications(0)
    })
  }
}
