import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  public _errorData: BehaviorSubject<any> = new BehaviorSubject<any>(1);
  constructor(@Inject(Injector) private readonly injector: Injector) {


  }
  private get utilityService() {
    return this.injector.get(ToastrService);
  }


  set user(value: any) {
    // Store the value
    this._errorData.next(value);
    //
  }

  get error$(): Observable<any> {
    return this._errorData.asObservable();
  }

  handleError(error: Error): void {
    this.user = error;
    this.utilityService.error('Application error!')
    console.log(error);

  }
}
