import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/common-service/http.service';

@Injectable({
  providedIn: 'root'
})
export class CommonUserService {

  constructor(private _httpService: HttpService) { }

  getUsers(page, verificationStatus, keyword?, limit?) {
    let params = new HttpParams();
    params = params.set('page', page);
    params = params.set('user_verify', verificationStatus);
    params = params.set('user_role_drc_key', '2');
    if (limit)
      params = params.set('limit', limit);
    if (keyword)
      params = params.set('search_key', keyword);

    return new Promise((resolve, reject) => {
      this._httpService.get('admin/client/getAll?' + params.toString()).subscribe((data: any) => {

        resolve(data.data)
      }, (error) => {
        reject()
      })
    })
  }

  getUsersList() {
    let params = new HttpParams();
    params = params.set('is_list', 1);
    params = params.set('user_verify', 1);
    params = params.set('user_role_drc_key', '2');

    return new Promise((resolve, reject) => {
      this._httpService.get('admin/attribute/user/role/getAll?' + params.toString()).subscribe((data: any) => {

        resolve(data.data)
      }, (error) => {
        reject()
      })
    })
  }

  getRolesList() {
    let params = new HttpParams();
    params = params.set('is_list', 1);
    return new Promise((resolve, reject) => {
      this._httpService.get('admin/attribute/user/role/getAll?' + params.toString()).subscribe((data: any) => {
        resolve(data.data)
      }, (error) => {
        reject(error)
      })
    })
  }

  toggleUser(userLoginId) {
    return new Promise((resolve, reject) => {
      this._httpService.post("admin/user/blockUnblock", { "user_login_key": userLoginId }).subscribe((data: any) => {
        if (data.success) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  deleteUser(userLoginId) {
    return new Promise((resolve, reject) => {
      this._httpService.post("admin/user/deleteUser", { "user_login_key": userLoginId }).subscribe((data: any) => {
        if (data.success) {
          resolve(data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject(error)
      })
    })
  }


  getUserById(clientId) {
    return new Promise((resolve, reject) => {
      this._httpService.get('common/user/getProfile?user_details_key=' + clientId).subscribe((data: any) => {
        if (data.success) {
          resolve(data.data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject()
      })
    })
  }
}
