<div class="form" *transloco="let t">
  <h3 mat-dialog-title>{{t('auth.title.enter_your_password')}}</h3>
  <form [formGroup]="checkExistingPasswordFormGroup">
    <div class="control-box">
      <mat-form-field appearance="outline">
        <mat-label>{{t('auth.title.enter_your_password')}}</mat-label>
        <input formControlName="password" placeholder="{{t('auth.title.enter_your_password')}}" matInput
          type="password">
        <mat-error *ngIf="checkExistingPasswordFormGroup.get('password').errors?.required">
          {{t('auth.validation.please_enter_your_password')}}
        </mat-error>
        <mat-error *ngIf="checkExistingPasswordFormGroup.get('password').errors?.incorrect">
          {{t('auth.validation.please_enter_correct_password')}}
        </mat-error>
        <mat-error *ngIf="checkExistingPasswordFormGroup.get('password').errors?.checkError">
          {{t('auth.message.error_while_checking_existing_password')}}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="right-align-controls">
      <button mat-button type="button" (click)="dialogRef.close()">{{t('actions.close')}}</button>
      <button class="success-button" type="button" (click)="checkPassword()"
        [disabled]="checkExistingPasswordFormGroup.invalid || isLoading" mat-button>
        <span *ngIf="!isLoading">{{t('check')}}</span>
        <div *ngIf="isLoading" style="padding: 8px;">
          <mat-spinner style="margin: auto;" [diameter]="20"></mat-spinner>
        </div>
      </button>
    </div>
  </form>
</div>