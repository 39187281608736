import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss']
})
export class FilterGroupComponent implements OnInit {

  @Output() filterChange = new EventEmitter();

  filterCtrl: FormControl = new FormControl(1)
  from: FormControl = new FormControl()
  to: FormControl = new FormControl()

  range
  today = new Date()

  isLoading

  filters = [
    { interval: 'today', period: 'daily', title: 'Today' },
    { interval: 'yesterday', period: 'daily', title: 'Yesterday' },
    { interval: 'monthly', duration: 3, title: '3M' },
    { interval: 'monthly', duration: 6, title: '6M' },
    { interval: 'monthly', duration: 9, title: '9M' },
    { interval: 'monthly', duration: 12, title: '1Y' },
    { interval: 'yearly', duration: 3, title: '3Y' },
    { interval: 'yearly', duration: 5, title: '5Y' },
    {}
  ]

  constructor() { }

  ngOnInit(): void {
    this.from.valueChanges.subscribe((data: any) => {
      this.range = new Date(moment(this.from.value).add(90, 'days').toLocaleString())
    })
  }

  change(isCustom) {
    if (!isCustom) {
      this.from.patchValue(null)
      this.to.patchValue(null)
    }
    this.filterChange.emit({ ...this.filters[this.filterCtrl.value - 1], from: this.from.value, to: this.to.value })
  }
}
