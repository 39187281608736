import { HttpBackend, HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  errorType: any;
  errorText: any;
  ref: any;

  private httpClient: HttpClient;

  constructor(
    private _activeRoute: ActivatedRoute,
    private _utility: UtilityService,
    private handler: HttpBackend,
  ) {
    this.httpClient = new HttpClient(handler);
  }

  ngOnInit(): void {
    this._activeRoute.params.subscribe((param) => {
      this.errorType = Number(param['type'])
    })
    this._activeRoute.queryParams.subscribe((param) => {
      this.ref = param['ref']
      this.errorText = param['error']
    })

    if (!this.errorText) {
      console.log(this.errorType);

      switch (this.errorType) {
        case 404:
          this.errorText = 'Service not found!'
          break;
        case 429:
          this.errorText = "Too many requests!";
          break;
        case 500:
          this.errorText = 'Internal server error!';
          break;
        case 503:
          this.errorText = 'Service unavailable!';
          break;
        case 700:
          this.errorText = 'Application error!';
          break;
        default:
          this.errorText = 'Unknown error!';
      }
    }
  }

  redirect() {
    window.location.replace(this.ref)
  }
}
