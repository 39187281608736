import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.scss']
})
export class VerifyUserComponent implements OnInit {

  isVerifying = true;
  isVerified = false;
  alreadyVerified = false;
  id;
  constructor(
    private _authService: AuthService,
    private route: ActivatedRoute,) {

    this.route.params
      .subscribe(
        (params: Params) => {
          this.id = params['id'];
          console.log(params)
        }
      );
  }

  ngOnInit(): void {
    this._authService.verifyEmail(this.id).then((data) => {
      this.isVerifying = false;
      this.isVerified = true;
    }).catch((errorCode) => {
      this.isVerifying = false;

      if (errorCode == 606) {
        this.alreadyVerified = true;
      }
      else {
        //this.isVerified = false;
      }
    })
  }

}
