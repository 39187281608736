import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFromMilliseconds'
})
export class DateFromMillisecondsPipe implements PipeTransform {

  transform(value): unknown {
    let date = new Date(Number(value))
    return date.toLocaleString();
  }

}
