import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-navigation-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BasicComponent implements OnInit {
  @Input() item
  @Input() index
  @Input() isSubmenu

  isActiveMatchOptions: any;

  ngOnInit(): void {
    this.isActiveMatchOptions = {
      paths: 'exact',
      fragment: 'exact',
      matrixParams: 'exact',
      queryParams: 'exact'
    };
  }

  ngAfterViewInit() {

  }

}
