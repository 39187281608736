import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { NoAuthGuard } from '../core/auth/guards/noAuth.guard';
import { LoginComponent } from './login/login.component';
import { UnlockSessionComponent } from './unlock-session/unlock-session.component';
import { SharedModule } from '../shared/shared.module';
import { MyAccountComponent } from './my-account/my-account.component';
import { MyAccountService } from './my-account/my-account.service';
import { AuthGuard } from '../core/auth/guards/auth.guard';
import { ChangePasswordModelComponent } from './my-account/change-password-model/change-password-model.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ProfileImageModelComponent } from './my-account/profile-image-model/profile-image-model.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { EditPersonalModelComponent } from './my-account/edit-personal-model/edit-personal-model.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { VerifyUserComponent } from './login/verify-user/verify-user.component';
const routes: Routes = [

  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    data: {
      layout: 'empty'
    },
  },
  {
    path: 'unlock-session/:userName',
    component: UnlockSessionComponent,
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    data: {
      layout: 'empty'
    },
  },
]
@NgModule({
  declarations: [
    LoginComponent,
    ChangePasswordModelComponent,
    ProfileImageModelComponent,
    EditPersonalModelComponent,
    UnlockSessionComponent,
    MyAccountComponent,
    VerifyUserComponent
  ],
  imports: [
    CommonModule,
    MatStepperModule,
    ImageCropperModule,
    MatCardModule,
    MatDatepickerModule,
    MatRadioModule,
    MatMenuModule,
    OverlayModule,
    RouterModule.forChild(routes),
    SharedModule
  ]
})
export class AuthPagesModule { }
