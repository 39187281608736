import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MyAccountService } from '../my-account.service';

@Component({
  selector: 'app-edit-personal-model',
  templateUrl: './edit-personal-model.component.html',
  styleUrls: ['./edit-personal-model.component.scss']
})
export class EditPersonalModelComponent implements OnInit {
  personalForm: FormGroup;
  floatLabelControl = new FormControl('auto');
  maxDate = new Date();
  isLoading = false;
  constructor(
    public dialogRef: MatDialogRef<EditPersonalModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private _myAccountService: MyAccountService,
  ) { }

  ngOnInit(): void {
    this.maxDate.setFullYear(new Date().getFullYear() - 18);
    console.log(this.maxDate)
    this.personalForm = this._fb.group({
      'firstName': [this.data.first_name, Validators.required],
      'lastName': [this.data.last_name, Validators.required],
      'email': [this.data.email_id, [Validators.required, Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]],
      'gender': [this.data.gender ? this.data.gender.toString() : '1'],
      'contact': [this.data.mobile_no, [Validators.required, Validators.minLength(10)]],
      'dob': [this.data.dob],
    })
  }

  editPersonalDetails() {
    this.isLoading = true;
    this._myAccountService.editPersonal(this.personalForm.value).then((data) => {

      this.dialogRef.close('success');
    }).finally(
      () => {
        this.isLoading = false;
      }
    )
  }

}
