import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, from, throwError } from 'rxjs';
import { catchError, map, retry, switchMap, tap, timeout } from 'rxjs/operators';
import { UtilityService } from './utility.service';
import { HttpService } from './http.service';
@Injectable({ providedIn: 'root' })
export class HttpIntercept implements HttpInterceptor {
  constructor(
    private _toastr: ToastrService,
    private _utility: UtilityService,
    private _httpService: HttpService,
    private _router: Router) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = encodeURIComponent(window.location.href);
    return next.handle(request)
      .pipe(
        timeout(20000),
        map((event: HttpEvent<any>) => {
          if (request.headers.get('skip'))
            return event;
          if (event instanceof HttpResponse && event.body.success == 0) {
            switch (event.body.status_code) {
              case 401:
                throw new HttpErrorResponse(
                  {
                    status: 401
                  }
                );
                break;
                this._toastr.error('Unauthorized access');
                localStorage.removeItem('accessToken')
                this._router.navigateByUrl('/login?force=true');
                break;
              case 500: // INTERNAL_SERVER_ERROR
                this._toastr.error(event.body.message);
                this.redirectTo('/error/' + event.body.status_code + '?error=Internal Server Error&ref=' + url)
                break;
              case 601: // USERNAME_NOT_VERIFIED
              case 604: // USER_NOT_VERIFIED
              case 605: // USER_BLOCKED
              case 606: // EMAIL_ALREADY_VERIFIED
              case 607: // EMAIL_NOT_VERIFIED
                this._toastr.error(event.body.message);
                break;
              case 503: // SYSTEM_DOWN
              case 650: // SYSTEM_DOWN
                this.redirectTo('/error/' + event.body.status_code + '?error=' + event.body.message + '&ref=' + url)
                break;
              case 609: // NO ACCESS
                this._toastr.error(event.body.message);
                break;
              case 200: // OK
                return event;
              case 608: // VALIDATION_ERROR
              case 603: // OTP_RESEND_LIMIT_REACHED
              case 408: // REQUEST CAN'T COMPLETED
                this._toastr.error(event.body.message);
                return event;
              default:
                this._toastr.error('Unknown error');
                throw new HttpErrorResponse({
                  error: event.body.message,
                  status: event.body.status_code
                })
            }
          }

          return event;
        }),

        catchError((error: HttpErrorResponse) => {
          if (error.name.toString() == 'TimeoutError') {
            this._toastr.warning('Request Time out!');
          }
          else {
            switch (error.status) {
              case 404:
                this._toastr.error('Service not found');
                break;
              case 401:
                return from(this.getRefreshToken()).pipe(switchMap((res: any) => {
                  if (res.success == 1) {
                    this.setRefreshToken(res.data['api_token'], res.data['refresh_token'])
                    request = request.clone({
                      setHeaders: {
                        Authorization: 'Bearer ' + res.data['api_token'],
                      },
                    });
                    return next.handle(request);
                  }
                  else {
                    this._toastr.error('Unauthorized access');
                    this._utility.clearSession()
                    this._router.navigateByUrl('/login?force=true');
                  }
                  return throwError(error);
                }));
                break;
              case 429: // NO ACCESS
                this._toastr.error("Too many requests");
                break;
              case 500:
                this._toastr.error('Internal server error');
                break;
              default:
                this._toastr.error('Unknown error');
            }
          }

          console.log("Error while calling " + error.url)
          return throwError(error);
        })
      )
  }

  redirectTo(url) {
    const tree: UrlTree = this._router.parseUrl(window.location.pathname);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g?.segments;
    if ((s && s[0].path != 'error') || !s)
      this._router.navigateByUrl(url, { replaceUrl: true, skipLocationChange: true })
  }

  getRefreshToken() {
    let key = this._utility.encrypt('accessToken')
    let tokenData = JSON.parse(this._utility.decryptAES(localStorage.getItem(key)))
    return this._httpService.post('refreshToken', {
      'refresh_token': tokenData.refreshToken,
      'username': tokenData.username
    }, true)
  }

  setRefreshToken(token, refreshToken) {
    let key =
      this._utility.encrypt('accessToken')
    let tokenData = JSON.parse(this._utility.decryptAES(localStorage.getItem(key)))
    let modifiedToken = tokenData;
    modifiedToken.token = token;
    modifiedToken.refreshToken = refreshToken;
    localStorage.setItem(key, this._utility.encryptAES(JSON.stringify(modifiedToken)).toString())
  }
}


export class DataNotFoundError extends Error {
  error = "Data not found";
}

export class UnknownError extends Error {
  error = "Something went wrong!";
}
