import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findBusinessDocument'
})
export class FindBusinessDocumentPipe implements PipeTransform {

  transform(documents: any[], type: any): any {
    return documents.find((document) => (document?.document_type?.document_type_drc_key == type));
  }

}
