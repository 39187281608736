
export const navigation: any = [
    {
        id: 1,
        title: 'Dashboard',
        url: 'dashboard',
        icon: 'home',
        type: 'basic'
    }

    , {
        id: 5,
        title: 'Domains',
        url: 'domain',
        icon: 'home',
        type: 'basic'
    },
    {
        id: 2,
        title: 'Hosting',
        icon: 'cloud',
        type: 'collapsable',
        children: [
            {
                id: 3,
                title: 'Analytics',
                url: 'hosting/analytics',
                type: 'basic',
                icon: 'cloud',
            },
            {
                id: 4,
                title: 'Server',
                url: 'hosting/server',
                type: 'basic',
                icon: 'cloud',
            }
        ]
    }
    , {
        id: 6,
        title: 'Clients',
        url: 'client',
        icon: 'group',
        type: 'basic'
    }
    , {
        id: 7,
        title: 'Users',
        url: 'user',
        icon: 'manage_accounts',
        type: 'basic'
    }
    , {
        id: 8,
        title: 'Projects',
        url: 'project',
        icon: 'devices_other',
        type: 'basic'
    }
    , {
        id: 9,
        title: 'Quotes',
        url: 'quote',
        icon: 'article',
        type: 'basic'
    }
    , {
        id: 10,
        title: 'Feedbacks',
        url: 'feedback',
        icon: 'feedback',
        type: 'basic'
    }
    , {
        id: 11,
        title: 'Attributes',
        icon: 'grid_view',
        type: 'collapsable',
        children: [
            {
                id: 12,
                title: 'Common',
                url: 'attribute/common',
                type: 'basic',
                icon: 'construction',
            },
            {
                id: 13,
                title: 'Hosting',
                url: 'attribute/hosting',
                type: 'basic',
                icon: 'cloud',
            }
        ]
    }, {
        id: 14,
        title: 'Feedbacks',
        url: 'feedback2',
        icon: 'feedback',
        type: 'basic'
    }
]