import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppComponent, SessionDialog } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { OverlayContainer } from '@angular/cdk/overlay';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { Routes, RouterModule, Route } from '@angular/router';
import { CountdownModule } from 'ngx-countdown';
import { MatIconModule } from '@angular/material/icon';
import { PushNotificationsModule } from 'ng-push-ivy';
import { MatDialogModule } from '@angular/material/dialog';
import { SparkModule } from 'src/@spark/spark.module';
import { SparkConfigModule } from 'src/@spark/services/config/config.module';
import { AuthService } from './core/auth/auth.service';
import { HttpIntercept } from './core/common-service/http-intercept';
import { CoreModule } from './core/core.module';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { sparkConfig } from './core/config/app.config';
import { MatButtonModule } from '@angular/material/button';
import { SPARK_APP_CONFIG } from 'src/@spark/services/config/config.constants';


import { MessagingService } from './core/messaging/messaging.service';
import { environment } from 'src/environments/environment';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { ErrorHandlerService } from './core/common-service/error-handler.service';
import { Ability, PureAbility } from '@casl/ability';
import { AbilityModule } from '@casl/angular';
import { AuthPagesModule } from './auth-pages/auth-pages.module';
import { DeleteModalComponent } from './components/common/delete-modal/delete-modal.component';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { TranslocoRootModule } from './transloco-root.module';
import { InitialDataResolver } from './app.resolvers';
import { MatTabsModule } from '@angular/material/tabs';
import { appRoutes } from './app.routing';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    SessionDialog,
    PageNotFoundComponent,
    DeleteModalComponent
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    RouterModule.forRoot(appRoutes),
    AuthPagesModule,
    MatTabsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    MatProgressBarModule,
    NgIdleKeepaliveModule.forRoot(),
    CountdownModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    PushNotificationsModule,
    CoreModule,
    SparkConfigModule.forRoot(sparkConfig),
    SparkModule,
    AbilityModule,
    SharedModule,
    TranslocoRootModule,
    // AngularFireDatabaseModule,
    // AngularFireAuthModule,
    // AngularFireMessagingModule,
    //AngularFireModule.initializeApp(environment.firebaseConfig),

    //ServiceWorkerModule.register('firebase-messaging-sw.js'), 
    provideMessaging(() => getMessaging()),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    ToastrModule.forRoot({ toastClass: localStorage.getItem("isDarkMode") == 'true' ? 'ngx-toastr ngx-toastr-dark' : 'ngx-toastr', preventDuplicates: true })
  ],
  providers: [
    AuthService,
    MessagingService,
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    { provide: 'config', useValue: SPARK_APP_CONFIG },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpIntercept,
      multi: true
    },
    {
      // Use the 'fill' appearance on Angular Material form fields by default
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      }
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer, library: FaIconLibrary,
    private _sparkConfigService: SparkConfigService) {
    library.addIconPacks(fas, fab);
    this._sparkConfigService.config$.subscribe((data: any) => {
      const config = data
      if (config.scheme == 'dark') {
        document.documentElement.classList.add('dark');
        overlayContainer.getContainerElement().classList.add('dark');
      }
      else {
        document.documentElement.classList.remove('dark');
        overlayContainer.getContainerElement().classList.remove('dark');
      }
    })
  }
}
