import { formatDate } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, combineLatest, debounceTime, map, shareReplay } from 'rxjs';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { SparkConfirmationService } from 'src/@spark/services/confirmation/confirmation.service';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  deleteConfigForm: FormGroup
  constructor(private _http: HttpClient,
    private _fb: FormBuilder,
    private _fuseConfirmationService: SparkConfirmationService,
    private _toaster: ToastrService) {

  }

  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox); 
    this.showBottomSuccess('Copied!')
  }

  toFormData(data, form, name?) {
    if (typeof data === 'object' && data != undefined) {
      for (let key in data) {
        if (data[key]) {
          if (typeof data[key] === 'object' && data[key] != null && !(data[key] instanceof File)) {
            if (name)
              this.toFormData(data[key], form, name + '[' + key + ']')
            else {
              this.toFormData(data[key], form, key)
            }
          }
          else {
            if (!name)
              form.append(key, data[key]);
            else
              form.append(name + '[' + key + ']', data[key]);
          }
        }

      }
    }
    else {
      form.append(name, data);
    }

    return form;
  }

  getFileName(path) {
    return path.replace(/^.*[\\\/]/, '');
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: any[] = []): any {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      const label = child.snapshot.data.breadcrumb;
      if (label) {
        breadcrumbs.push({ label, url });
      }

      return this.buildBreadCrumb(child, url, breadcrumbs);
    }
  }

  // password and confirm password check
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  checkMaxValueLimit(controlName: string, limit) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      // set error on matchingControl if validation fails
      if (control.errors && !control.errors.limit) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      if (control.value > limit) {
        control.setErrors({ limit: true });
      } else {
        control.setErrors(null);
      }
    }
  }
  checkMinValueLimit(controlName: string, limit) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      // set error on matchingControl if validation fails
      if (control.errors && !control.errors.limit) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      if (control.value < limit) {
        control.setErrors({ limit: true });
      } else {
        control.setErrors(null);
      }
    }
  }


  showSuccess(message: String) {
    return this._toaster.success(message.toString(), '', { progressBar: true });
  }
  welcomeUser(message: String) {
    return this._toaster.success("Welcome to SPARK Technologies", 'Hey, ' + message, { progressBar: true });
  }
  showFailure(message: String) {
    return this._toaster.error(message.toString());
  }
  showWarning(message: String) {
    return this._toaster.warning(message.toString());
  }

  showBottomSuccess(message: String) {
    this._toaster.show(message.toString(), '', {
      positionClass: 'toast-bottom-center',
      toastClass: 'ngx-toastr process-toaster',
    });
  }

  showBottomWarning(message: String) {
    this._toaster.warning(message.toString(), '', {
      positionClass: 'toast-bottom-center',
      toastClass: 'ngx-toastr',
    });
  }

  showProcess(message: String, id) {
    return this._toaster.show(message.toString(), '',
      {
        positionClass: 'toast-bottom-center',
        toastClass: 'ngx-toastr process-toaster',
        timeOut: 0,
        tapToDismiss: false,
        progressBar: true
      }).toastId = id;
  }

  removeProcess(id) {
    this._toaster.remove(id);
  }

  validationList: any[] = [
    { 'name': "At least one digit", "status": false },
    { 'name': "min 8 character", "status": false },
    { 'name': "At least one lower case", "status": false },
    { 'name': "At least one upper case", "status": false },
    { 'name': "At least one special character", "status": false },
  ];

  deleteDialogue(data) {
    let parser = new DOMParser();
    let tg = parser.parseFromString('Are you sure to remove <b>' + data + '</b>?', 'text/html').body;
    return Swal.fire({
      text: tg.textContent?.toString(),
      showCancelButton: true,
      icon: 'warning',
      reverseButtons: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      confirmButtonColor: 'red',
      heightAuto: false,
      customClass: {
        confirmButton: 'delete-button',
      },
      cancelButtonColor: '#dadada',
    });
  }

  showConfirm(message) {
    let parser = new DOMParser();
    let tg = parser.parseFromString(message, 'text/html').body;
    return Swal.fire({
      text: tg.textContent?.toString(),
      showCancelButton: true,
      icon: 'warning',
      reverseButtons: true,
      confirmButtonText: 'Yes, confirm!',
      cancelButtonText: 'No',
      confirmButtonColor: 'green',
      heightAuto: false,
      customClass: {
        confirmButton: 'delete-button',
      },
      cancelButtonColor: '#dadada',
    });
  }

  restoreDialogue(data) {
    let parser = new DOMParser();
    let tg = parser.parseFromString('Are you sure to restore <b>' + data + '</b>?', 'text/html').body;
    console.log(tg)
    return Swal.fire({
      text: tg.textContent?.toString(),
      showCancelButton: true,
      confirmButtonText: 'Yes, restore it!',
      cancelButtonText: 'No, keep it',
      confirmButtonColor: '#01a317',
      heightAuto: false,
      cancelButtonColor: '#dadada',
    });
  }

  generatePassword(length?: number) {
    const passwordLength = length || 12;

    const lowerCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    const upperCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const symbols = ['!', '?', '@', ')', '(', '%', '$', '#'];

    const getRandom = array => array[Math.floor(Math.random() * array.length)];

    let finalCharacters = '';

    finalCharacters = finalCharacters.concat(getRandom(upperCharacters));

    finalCharacters = finalCharacters.concat(getRandom(numbers));


    finalCharacters = finalCharacters.concat(getRandom(symbols));


    for (let i = 0; i < passwordLength - 3; i++) {
      finalCharacters = finalCharacters.concat(getRandom(lowerCharacters));
    }

    return finalCharacters.split('').sort(() => 0.5 - Math.random()).join('');
  }

  counter(i: number) {
    return new Array(i);
  }

  buildGraphParam(filter) {
    let params = new HttpParams();
    if (filter?.duration)
      params = params.set('duration', filter.duration);
    if (filter?.interval)
      params = params.set('interval', filter.interval);
    if (filter?.period || filter?.interval)
      params = params.set('period', filter.period ?? filter.interval);
    else {
      if (filter?.from)
        params = params.set('from', formatDate(filter?.from, 'yyyy-MM-dd', 'en-in'));
      if (filter?.to)
        params = params.set('to', formatDate(filter?.to, 'yyyy-MM-dd', 'en-in'));
      params = params.set('period', 'monthly');
    }
    return params
  }

  dateConvert(date) {
    return date ? formatDate(date, 'yyyy-MM-dd', 'en-in') : ''
  }

  dirtyCheck<U>(source: Observable<U>) {
    return function <T>(valueChanges: Observable<T>): Observable<boolean> {
      const isDirty$ = combineLatest(
        source,
        valueChanges,
      ).pipe(
        debounceTime(300),
        map(([a, b]) => _.isEqual(a, b) === false),
        shareReplay({ bufferSize: 1, refCount: true }),
      );
      return isDirty$;
    };
  }

  redirect(link, target) {
    window.open(link, target)
  }

  encrypt(data) {
    let key = environment.isDebug ? data : CryptoJS.SHA256(data).toString();
    return key;
  }

  decryptAES(data) {
    try {
      return environment.isDebug ? data : CryptoJS.AES.decrypt(data, environment.passphrase).toString(CryptoJS.enc.Utf8)
    } catch (error) {
      //this._router.navigateByUrl('/error/700?ref=' + encodeURIComponent(window.location.href))
    }
  }

  encryptAES(data) {
    let key = environment.isDebug ? data : CryptoJS.AES.encrypt(data, environment.passphrase).toString()
    return key;
  }

  clearSession() {
    localStorage.removeItem(this.encrypt('accessToken'))
    localStorage.removeItem('userKey')
  }

  toProperCase(str) {
    let newStr = str.replace('-', ' ').replace('_', ' ')
    return newStr.charAt(0).toUpperCase() + newStr.slice(1);
  };
}
