import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationModule } from './components/navigation/navigation.module';
import { ClickOutsideDirective } from './components/directives/click-outside.directive';
import { DragDropDirectiveDirective } from './components/directives/drag-drop-directive.directive';
import { OnlyNumbersDirective } from './components/directives/only-numbers.directive';
import { TitleCaseDirective } from './components/directives/title-case.directive';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SparkConfirmationModule } from './services/confirmation/confirmation.module';
import { TailwindService } from './services/tailwind.service';
import { SparkSplashScreenModule } from './services/splash-screen/splash-screen.module';

@NgModule({
  declarations: [

    DragDropDirectiveDirective,
    OnlyNumbersDirective,
    TitleCaseDirective
  ],
  providers: [
    TailwindService
  ],
  imports: [
    CommonModule,
    NavigationModule,
    HttpClientModule,
    MatIconModule,
    MatDialogModule,
    SparkConfirmationModule,
    SparkSplashScreenModule
  ],
  exports: [
    NavigationComponent
  ]
})
export class SparkModule { }
