import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ClickOutsideDirective } from '../directives/click-outside.directive';
import { SparkModule } from 'src/@spark/spark.module';
import { TranslocoRootModule } from '../transloco-root.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { SharedModule } from '../shared/shared.module';
import { AllNotificationsComponent } from './all-notifications/all-notifications.component';
@NgModule({
  declarations: [LayoutComponent, AllNotificationsComponent],
  imports: [
    CommonModule, RouterModule,
    MatSidenavModule,
    MatTooltipModule,
    MatBadgeModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    SparkModule,
    TranslocoRootModule,
    SharedModule
  ],
  exports: [
    LayoutComponent,
    FlexLayoutModule,
  ],
  providers: [
  ]
})
export class LayoutModule { }
