import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpService } from 'src/app/core/common-service/http.service';
@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  public onQuoteChange: BehaviorSubject<any>;
  url = environment.APIUrl;
  constructor(
    private _httpService: HttpService,
  ) {
    this.onQuoteChange = new BehaviorSubject(null);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise((resolve, reject) => {
      this.getQuotes(0);
      resolve({})
    })
  }

  getQuotes(page, keyword?, limit?) {
    let params = new HttpParams();
    params = params.set('page', page);
    if (limit)
      params = params.set('limit', limit);
    if (keyword)
      params = params.set('search_key', keyword);
    return new Promise((resolve, reject) => {
      this._httpService.get('admin/quote/getAll').subscribe((data: any) => {
        this.onQuoteChange.next(data.data)
        resolve(data.data)
      }, (error) => {
        reject(error)
      })
    })
  }

  deleteQuotes(quote) {
    return new Promise((resolve, reject) => {
      this._httpService.post(this.url + 'qoute/deleteQuote', JSON.stringify(quote)).subscribe((data: any) => {
        this.getQuotes(0)
      }, (error) => {
        reject()
      })
    })
  }

}
