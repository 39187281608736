import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router, RouterStateSnapshot } from '@angular/router';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { LocationService } from 'src/app/core/location/location.service';

@Component({
  selector: 'app-unlock-session',
  templateUrl: './unlock-session.component.html',
  styleUrls: ['./unlock-session.component.scss']
})
export class UnlockSessionComponent implements OnInit {
  unlockForm: FormGroup
  isLoading: boolean;
  username;
  redirectUrl = '/';
  locationInfo: any;

  constructor(private _fb: FormBuilder,
    private _utility: UtilityService,
    private _authService: AuthService,
    private _router: Router,
    private _locationService: LocationService,
    private _activeRoute: ActivatedRoute,
    public _configService: SparkConfigService,
  ) {
    this._activeRoute.params
      .subscribe(
        (params: Params) => {
          this.username = params['userName'];
        }
      );
    let params = this._activeRoute.snapshot.queryParams;
    if (params['redirectURL']) {
      this.redirectUrl = params['redirectURL'];
    }
  }

  ngOnInit(): void {
    this.unlockForm = this._fb.group({
      'username': [{ value: this.username, disabled: true }, Validators.required],
      'password': ['', Validators.required],
      'latitude': [''],
      'longitude': [''],
    })

    this._locationService.getPosition().then((result) => {
      this.locationInfo = result
      this.unlockForm.get("longitude").patchValue(this.locationInfo.longitude)
      this.unlockForm.get("latitude").patchValue(this.locationInfo.latitude)
    })
  }

  unlock() {
    this.isLoading = true;
    this._authService.signIn(this.unlockForm.getRawValue()).subscribe((data) => {
      this.isLoading = false;
      this._router.navigateByUrl(this.redirectUrl)
    },
      (() => {
        this.isLoading = false;
      })
    )
  }

}
