import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpService } from '../common-service/http.service';
import { Idle } from '@ng-idle/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { UserService } from '../user/user.service';
import { UtilityService } from '../common-service/utility.service';
import { MessagingService } from '../messaging/messaging.service';
import { NotificationService } from '../messaging/notification.service';

@Injectable()
export class AuthService {
  apiUrl = environment.APIUrl;
  private _authenticated: boolean = false;
  deviceInfo;
  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _httpService: HttpService,
    private _utility: UtilityService,
    private idle: Idle,
    private deviceService: DeviceDetectorService,
    private _messagingService: MessagingService,
    private _notificationService: NotificationService,
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  setAccessToken(token: string, refreshToken, username) {
    let value = { token: token, refreshToken: refreshToken, username: username }
    localStorage.setItem(this._utility.encrypt('accessToken'), this._utility.encryptAES(JSON.stringify(value)));
  }

  getAccessToken(): string {
    return localStorage.getItem(this._utility.encrypt('accessToken')) ?? '';
  }


  set accessUserKey(token: string) {
    localStorage.setItem('userKey', token);
  }

  get accessUserKey(): string {
    return localStorage.getItem('userKey') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  verifyEmail(id) {
    return new Promise((resolve, reject) => {
      this._httpService.post("public/verifyUserByEmailID", { verify_user: id }).subscribe((data: any) => {
        if (data.success) {
          resolve(data.data)
        }
        else {
          reject(data.status_code)
          //this._toastr.error("Email id doen't exist!")
        }
      }, (error) => {
        reject(500)
      });
    });
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(data): Observable<any> {
    // Throw error, if the user is already logged in

    data.user_fcm_token = this._messagingService.token$ ?? ''
    data.user_fcm_token = ''
    data.browser_name = this.deviceInfo.browser
    data.browser_version = this.deviceInfo.browser_version
    data.os = this.deviceInfo.os
    data.os_version = this.deviceInfo.os_version
    data.device_type = '0'
    return this._httpService.post('login', data, true).pipe(
      switchMap((response: any) => {

        if (response.success == 1) {
          this._utility.welcomeUser(response.data.first_name)
          // Store the access token in the local storage
          this.setAccessToken(response.data.api_token, response.data.refresh_token, response.data.username);
          this.accessUserKey = response.data.user_details_key;

          this.idle.watch()
          this._notificationService.start()
          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = response.data;
          // Return a new observable with the response

        }
        return of(response);
      })
    );
  }

  /**
   * Sign in using the access token

  signInUsingToken(): Observable<any> {
      // Renew token
      return this._httpService.post('api/auth/refresh-access-token', {
          accessToken: this.accessToken
      }).pipe(
          catchError(() =>

              // Return false
              of(false)
          ),
          switchMap((response: any) => {

              // Store the access token in the local storage
             // this.accessToken = response.accessToken;

              // Set the authenticated flag to true
              this._authenticated = true;

              // Store the user on the user service
              this._userService.user = response.user;


              return of(true);
          })
      );
  }
*/
  /**
   * Sign out
   */
  signOut() {
    let sessionData = this.getAccessToken() ? JSON.parse(this._utility.decryptAES(this.getAccessToken())) : ''
    return new Promise((resolve, reject) => {
      this._httpService.postWithAuth('logout', { api_token: sessionData.token })
        .subscribe((data: any) => {
          if (data.success == 1) {
            resolve(data)
            this._utility.clearSession()
            this._authenticated = false
          }
          else {
            this._utility.clearSession()
            this._authenticated = false
            reject(data)
          }
          this.idle.stop()
        }, (error) => {
          this._utility.clearSession()
          this._authenticated = false
          reject(error)
          this.idle.stop()
        });

      this._notificationService.stop()
    })

  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(data) {
    data.user_role_drc_key = 2;
    data.url = window.location.origin + "/verifyClient";
    return new Promise((resolve, reject) => {
      this._httpService.post('public/registerUser', data).subscribe((data: any) => {
        if (data.success) {
          resolve(data)
        }
        else {
          reject(data)
        }
      })
    })
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {

    // Check if the user is logged in
    if (this._authenticated) {
      this.idle.watch()
      this._notificationService.start()
      return of(true);
    }

    // Check the access token availability
    if (this.getAccessToken()) {
      this.idle.watch()
      this._notificationService.start()
      return of(true);

    }

    // Check the access token expire date
    // if (AuthUtils.isTokenExpired(this.accessToken)) {
    //     return of(false);
    // }

    // If the access token exists and it didn't expire, sign in using it
    return of(false);
  }
}
