<div class="component-body">
    <div class="middle-box mat-app-background" *transloco="let t">
        <h3 *ngIf="isVerifying">
            <mat-spinner style="margin: auto;" [diameter]="40"></mat-spinner>
            <p class="verify-title">{{t('auth.title.verifying_email')}}</p>
        </h3>
        <ng-container *ngIf="!isVerifying && isVerified">
            <h3 class="verify-title">
                <mat-icon style="color: green;">verified</mat-icon>
                <span
                    style="vertical-align: middle;margin-left: 8px;">{{t('auth.message.your_mail_verified_successfully')}}</span>
            </h3>
            <p class="message">{{t('auth.message.you_will_soon_able_to_use')}} <br>
                {{t('auth.message.our_portal_after_admin_approval')}}</p>
            <!-- <p routerLink="/login">login here</p> -->
        </ng-container>
        <ng-container *ngIf="!isVerifying && !isVerified && !alreadyVerified">
            <h3 class="verify-title">
                <mat-icon style="color: red;">error</mat-icon>
                <span
                    style="vertical-align: middle;margin-left: 8px;">{{t('auth.message.unable_to_verify_email')}}</span>
            </h3>
            <!-- <p class="message">You will soon able to use <br> our portal after admin approve!</p> -->
            <!-- <p routerLink="/login">login here</p> -->
        </ng-container>
        <ng-container *ngIf="!isVerifying && alreadyVerified ">
            <h3 class="verify-title">
                <mat-icon style="color: red;">warning</mat-icon>
                <span
                    style="vertical-align: middle;margin-left: 8px;">{{t('auth.message.email_already_verified')}}</span>
                <p class="message small-message"><a href="" routerLink="/login">{{t('click_here')}}</a>
                    {{t('auth.title.to_login')}}</p>
            </h3>
            <!-- <p class="message">You will soon able to use <br> our portal after admin approve!</p> -->
            <!-- <p >login here</p> -->
        </ng-container>
    </div>
</div>