import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayToString'
})
export class ArrayToStringPipe implements PipeTransform {

  transform(value: any[], ...args: any[]): unknown {
    return value.map((val) => val[args[0]]).join(args[1]);
  }

}
