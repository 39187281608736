import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'bytesConvertor'
})
export class BytesConvertorPipe implements PipeTransform {

  transform(value, unit?): any {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(value, 10) || 0;
    while ((unit ? l < unit : n >= 1024) && ++l) {
      n = n / 1024;
    }
    return { value: n.toFixed(n < 10 && l > 0 ? 2 : 0), unit: units[l] };
  }

}
