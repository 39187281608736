import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ManageMenuService } from 'src/app/components/accessibility/manage-menu/manage-menu.service';
import { HttpService } from '../common-service/http.service';
import { UserService } from '../user/user.service';
@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private onLoadNavigation: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  userData: any;
  constructor(private _httpService: HttpService,
    private menuService: ManageMenuService,
    private userService: UserService) {
    this.userService.user$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((data) => {
        this.userData = data
      })

    this.menuService.onChangeMenu$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe(() => {
        this._httpService.get('common/menu/getMenuList?user_details_key=' + localStorage.getItem('userKey').toString()).subscribe((data: any) => {
          this.onLoadNavigation.next(data.data)
        })
      })

  }

  get navigation$(): Observable<any> {
    return this.onLoadNavigation.asObservable();
  }

  getAllMenus() {
    return this._httpService.get('common/menu/getMenuList?user_details_key=' + localStorage.getItem('userKey').toString()).pipe(
      tap((data: any) => {
        this.onLoadNavigation.next(data.data)
      }))
  }
}
