import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkField'
})
export class CheckFieldPipe implements PipeTransform {

  transform(object: any, ...key: any): unknown {
    if (object)
      return (key in object) && object[key] != null && object[key] != undefined;
    else
      return null
  }

}
