import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { reject } from 'lodash';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CommonApiService {

  constructor(private _httpService: HttpService,
    private _http: HttpClient) { }

  getMaterialIcons() {
    return new Promise((resolve, reject) => {
      this._httpService.get('admin/gl/icons/getIcons').subscribe((data: any) => {
        resolve(data.data)
      }, (error) => {
        reject(error)
      })
    })
  }


  // Storage unit
  getStorageUnitsList() {
    return new Promise((resolve, reject) => {
      this._httpService.get('admin/attribute/server/storage/unit/getAll?is_list=1').subscribe((data: any) => {

        resolve(data.data)
      }, (error) => {
        reject(error)
      })
    })
  }

  getPrefixes() {
    return new Promise((resolve, reject) => {
      this._httpService.get('admin/attribute/user/prefix/getAll?is_list=1').subscribe((data: any) => {
        resolve(data.data);
      },
        (error) => {

          reject(error)
        })
    })
  }

  // get country codes
  getCountryCode() {
    return new Promise((resolve, reject) => {
      this._httpService.get('public/attribute/country/getCodes').subscribe((data: any) => {

        resolve(data.data)
      }, (error) => {
        reject(error)
      })
    })
  }

  getCountries() {
    return new Promise((resolve, reject) => {
      this._httpService.get('admin/attribute/country/getAll?is_list=1').subscribe((data: any) => {
        if (data.status_code == 200) {

          resolve(data.data);
        }
        else {
          reject(data)
        }
      },
        (error) => {
          reject(error)
        })
    })
  }

  getStatesByCountry(countryId) {
    let params = new HttpParams();
    params = params.set('country_drc_key', countryId)
    params = params.set('is_list', 1)
    return new Promise((resolve, reject) => {
      this._httpService.get('public/attribute/state/getAll?' + params).subscribe((data: any) => {
        if (data.status_code == 200)
          resolve(data.data)
        else
          reject(data)
      }, (error) => {
        reject(error)
      })
    })
  }

  getStateList() {
    let params = new HttpParams();
    params = params.set('is_list', 1)
    return new Promise((resolve, reject) => {
      this._httpService.get('admin/attribute/state/getAll?' + params).subscribe((data: any) => {
        if (data.status_code == 200)
          resolve(data.data)
        else
          reject(data)
      }, (error) => {
        reject(error)
      })
    })
  }

  getCitiesByState(stateId) {
    let params = new HttpParams();
    params = params.set('state_drc_key', stateId)
    params = params.set('is_list', 1)
    return new Promise((resolve, reject) => {
      this._httpService.get('public/attribute/city/getAll?' + params).subscribe((data: any) => {
        if (data.status_code == 200)
          resolve(data.data)
        else
          reject(data)
      }, (error) => {
        reject(error)
      })
    })
  }

  getCityList() {
    let params = new HttpParams();
    params = params.set('is_list', 1)
    return new Promise((resolve, reject) => {
      this._httpService.get('admin/attribute/city/getAll?' + params).subscribe((data: any) => {
        if (data.status_code == 200)
          resolve(data.data)
        else
          reject(data)
      }, (error) => {
        reject(error)
      })
    })
  }

  getInfo() {
    let params = '';
    params += 'search_key[]=tax'
    return new Promise((resolve, reject) => {
      this._httpService.get('public/company/getInfo?' + params).subscribe((data: any) => {
        if (data.status_code == 200)
          resolve(data.data)
        else
          reject(data)
      }, (error) => {
        reject(error)
      })
    })
  }


}
