import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fromSnakecase'
})
export class FromSnakecasePipe implements PipeTransform {

  transform(value): unknown {
    return value
      .split("_")
      .filter(x => x.length > 0)
      .map((x) => (x.charAt(0).toUpperCase() + x.slice(1)))
      .join(" ");

  }

}
