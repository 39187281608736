import { Pipe, PipeTransform } from '@angular/core';
import { type } from 'os';

@Pipe({
  name: 'findInArray',
})
export class FindInArrayPipe implements PipeTransform {

  transform(array: any, ...args: any[]): any {
    let property = args[0].split('.')
    let data = array.find((item) => {
      return this.findIt(property, args, item)
    })
    return data || data && data.length > 0 ? data : false
  }

  findIt(property, args, item) {
    if (property[0] in item && !(item[property[0]] instanceof Object)) {
      if (item[property[0]] == args[1]) {
        return item
      }
    }
    else if (property[0] in item && item[property[0]] instanceof Object) {
      for (let key in item[property[0]]) {
        if (item[property[0]][key] == args[1]) {
          return item
        }
      }
    }
  }
}
