import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterStateSnapshot } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { navigation } from './navigation';
import { PushNotificationsService } from 'ng-push-ivy';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountdownComponent } from 'ngx-countdown';
import { AppService } from './app.service';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { AuthService } from './core/auth/auth.service';
import { UserService } from './core/user/user.service';
import { MessagingService } from './core/messaging/messaging.service';
import { getToken, Messaging, onMessage } from 'firebase/messaging';
import { CurrentRouteService } from './core/common-service/current-route.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SparkAdmin';
  navigation: any;
  isLoading = false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  sessionForm: FormGroup
  remainTime = 900;
  countDown: Subscription;
  dialogRef;
  user;
  redirectURL = ''
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  message: any;
  constructor(
    private router: Router,
    private idle: Idle,
    private messagingService: MessagingService,
    private _pushNotifications: PushNotificationsService,
    private _configService: SparkConfigService,
    private _userService: UserService,
    public dialog: MatDialog) {

    this.navigation = navigation;
    this.router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe((event: any) => {
      dialog.closeAll()
      switch (true) {
        case event instanceof NavigationEnd: {
          this.redirectURL = event.url
          break;
        }
        default: {
          break;
        }
      }
    })

    this._configService.config$.subscribe((options) => {
      if (options?.idleTime && this.remainTime != options?.idleTime) {
        this.remainTime = options.idleTime
        //this.remainTime = 10
        this.idle.setIdle(this.remainTime);
      }
    });

    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
      this.user = data
    })

    this._pushNotifications.requestPermission();
    this.idle.setIdle(this.remainTime);
    this.idle.setTimeout(20);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';

    })

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.idle.stop()
      this.dialogRef.close('lock')
    });

    this.idle.onIdleStart.subscribe(() => {
      this.notify();
      this.idleState = 'You\'ve gone idle!'
      this.idle.stop()
      this.dialogRef = this.dialog.open(SessionDialog, {
        width: '400px',
        data: '',
        disableClose: true
      });
      this.dialogRef?.afterClosed().subscribe((result) => {
        this.idle.stop()
        if (result == 'stay') {
          this.idle.watch()
        }
        else if (result == 'lock') {
          this.router.navigate(['unlock-session', this.user?.user_login?.username], { queryParams: { force: true, redirectURL: this.redirectURL } });
          //this.router.navigateByUrl('/unlock-session/' + this.user?.user_login?.username + '?force=true&redirectURL=' + routerStateSnapshot.url)
        }
        else if (result == 'logout') {
          this.router.navigateByUrl('/login?force=true')
        }

      });

    });

  }

  notify() { //our function to be called on click
    let options = { //set options
      body: "Locking session in 20 seconds!",
      sticky: true,
      vibrate: [2],
      icon: 'assets/logo/iconmark_gradient02.svg'
    }
    this._pushNotifications.create('You found idle!', options).subscribe( //creates a notification
      res => {
        if (res.event.type === 'click') {
          window.focus();
          res.notification.close();
        }
      },
      err => { }
    );
  }

  ngOnInit() {
    // this._navigationService.loadNavigation(this.navigation)
    // this.messagingService.requestPermission()
    // this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(0);
    this._unsubscribeAll.complete();
  }
}

@Component({
  selector: 'session-dialog',
  template: `<h1 mat-dialog-title>
  <mat-icon class="text-red-500" style="vertical-align:middle">timelapse</mat-icon>
  <span class="align-middle ml-2" style="vertical-align:middle">You found Idle!</span></h1>
  <div mat-dialog-content>
  <ng-container *ngIf="!isLoggingOut">
    <p style="font-size: 15px;">System will be locked in <b>
    <countdown #cd [config]="{leftTime: 20,format: 'ss'}" (event)="handleEvent($event)"></countdown></b></p>
  </ng-container>
  <ng-container *ngIf="isLoggingOut">
      <p style="font-size: 15px;">Logging out system...</p>
  </ng-container>
  </div>
  <div mat-dialog-actions class="justify-end">
    <button mat-button class="add-round-button" (click)="logout()">Logout</button>
    <button mat-button class="success-button add-round-button" color="primary" (click)="this.dialogRef.close('stay')" cdkFocusInitial>Stay</button>
  </div>`,
})
export class SessionDialog {
  isLoggingOut: boolean = false;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  constructor(
    public dialogRef: MatDialogRef<SessionDialog>,
    private authService: AuthService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.countdown?.begin()
  }

  handleEvent(event) {
    if (event.action == 'done') {
      this.isLoggingOut = true
      this.authService.signOut().then((data) => {
        this.dialogRef.close('lock');
      }).catch((error) => {
        this.dialogRef.close('lock');
      });

    }
  }

  logout() {
    this.isLoggingOut = true
    this.authService.signOut().then((data) => {
      this.dialogRef.close('logout')
    }).catch((error) => {
      this.dialogRef.close('lock');
    });
  }

}
