<div class="flex h-full w-full items-center justify-center flex-col mb-6" *transloco="let t">
  <container-element [ngSwitch]="errorType">
    <img width="550" *ngSwitchCase="503" [src]="'assets/illustrations/server-down.png'">
    <img width="550" *ngSwitchCase="650" [src]="'assets/illustrations/server-down.png'">
    <img width="550" *ngSwitchCase="612" [src]="'assets/illustrations/security-pana.png'">
    <img width="550" *ngSwitchDefault [src]="'assets/illustrations/unknown-error.png'">
  </container-element>

  <h3 class="mt-12 text-5xl sm:text-4xl text-center">{{errorText}}</h3>
  <ng-container>
    <button mat-stroked-button color="primary" class="mt-6 add-round-button"
      (click)="redirect()">{{t('actions.try_again')}}</button>
  </ng-container>
</div>