import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { format } from 'path';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from 'src/app/core/common-service/http.service';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { User } from 'src/app/models/User';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {
  public onAccountChange: BehaviorSubject<any>;
  url = environment.APIUrl;
  constructor(
    private _httpService: HttpService,
    private _utility: UtilityService) {
    this.onAccountChange = new BehaviorSubject(null);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise((resolve, reject) => {
      this.getAccountDetails();
      resolve({})
    })
  }

  getAccountDetails() {
    // return new Promise((resolve, reject) => {
    //   this._http.get('/api/common/user/getMyProfile?user_key=' + localStorage.getItem('user_id')).subscribe((data: any) => {
    //     this.onAccountChange.next(data.data)
    //   }, (error) => {
    //     this._error.showError(error);
    //     reject(error)
    //   })
    // })
  }

  getUserProfile() {
    return new Promise((resolve, reject) => {
      this._httpService.get('common/user/getProfile').subscribe((data: any) => {
        if (data.success) {
          resolve(data.data)
        }
        else {
          reject(data)
        }
      }, (error) => {
        reject()
      })
    })
  }

  editPersonal(personalData: any) {
    personalData.dob = formatDate(personalData.dob, 'yyyy-MM-dd', 'en_US')
    return new Promise((resolve, reject) => {
      this._httpService.post('common/user/updateMyProfile', personalData).subscribe((data: any) => {
        if (data.success) {
          this._utility.showSuccess("Personal details updated successfully!")
          resolve({});
        }
        else {
          // this._utility.showFailure("Error while updating Personal details!")
          reject();
        }
      }, (error) => {
        reject()
      })
    })
  }

  editProfileImage(imageData: any) {
    return new Promise((resolve, reject) => {
      this._httpService.post('common/user/updateProfilePicture', { 'user_profile': imageData.replace("data:image/png;base64,", "") }).subscribe((data: any) => {
        if (data.success) {
          this._utility.showSuccess("Profile image updated successfully!")
          resolve({});
        }
        else {
          this._utility.showFailure("Error while updating profile image!")
          reject();
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  removeProfileImage() {
    return new Promise((resolve, reject) => {
      this._httpService.post('common/user/updateProfilePicture', { 'user_profile': '' }).subscribe((data: any) => {
        if (data.status_code == 200) {
          this._utility.showSuccess("Profile image removed successfully!")
          resolve(data);
        }
        else {
          this._utility.showFailure("Error while removing profile image!")
          reject(data);
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  checkPassword(oldPassword) {
    return new Promise((resolve, reject) => {
      this._httpService.post('common/user/checkPassword', { 'password': oldPassword }).subscribe((data: any) => {
        if (data.status_code == 200) {
          resolve(data);
        }
        else {
          reject()
        }
      }, (error) => {
        reject(error)
      })
    })
  }

  changePassword(password) {
    return new Promise((resolve, reject) => {
      this._httpService.post('common/user/changePassword', { 'password': password }).subscribe((data: any) => {
        if (data.success == 1) {
          resolve(data);
        }
        else {
          reject()
        }
      }, (error) => {
        reject(error)
      })
    })
  }
}
