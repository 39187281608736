import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MyAccountService } from '../my-account.service';

@Component({
  selector: 'app-profile-image-model',
  templateUrl: './profile-image-model.component.html',
  styleUrls: ['./profile-image-model.component.scss']
})
export class ProfileImageModelComponent implements OnInit {
  isLoading = true;
  isUploading = false;

  constructor(
    private _myAccountService: MyAccountService,
    public dialogRef: MatDialogRef<ProfileImageModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    let vr;
    this.imageChangedEvent = this.data;
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.isLoading = false;
    // show cropper
  }
  cropperReady() {
    // cropper ready

  }
  loadImageFailed() {
    this.isLoading = false;
    // show message
  }

  setImage() {
    this.isUploading = true;
    this._myAccountService.editProfileImage(this.croppedImage).then((data) => {

      this.dialogRef.close('success')
    }).catch(() => {
      this.isUploading = false;
    })
  }
}
